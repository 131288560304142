import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Button,
  Alert as Alert2,
  Spinner,
  Modal,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from "reactstrap";
import {
  Header,
  Footer,
  Loading,
  CustomerInformation,
  CustomerInformation2,
  UnitInformation,
  ContractBuild,
  ContractPricing,
  Payment,
  QuotePricing
} from "./";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import Alert from "react-s-alert-v3";
import {useParams, Redirect, useHistory} from "react-router-dom";
import classnames from "classnames";
import { useContractStore } from "../hooks";
import { api, ui_helpers, date_helpers, input_validation } from "../helpers";
import { storage, constants, UserContext } from "../utils";

const COMMON_PRICE_ADJUSTMENT_OPTIONS = [
  {label: 'Flat Upcharge', label2: 'This adjustment will increase the unit price by the $ amount entered.', description: '', value: 4, 
    amount: '', percentage: '', includeInUnitPrice: true, isNegative: false, isPercent: false},
  {label: 'Flat Discount', label2: 'This adjustment will decrease the unit price by the $ amount entered.', description: '', value: 5, 
    amount: '', percentage: '', includeInUnitPrice: true, isNegative: true, isPercent: false},
  {label: 'Percent Upcharge', label2: 'This adjustment will increase the unit price by the percentage entered.', description: '', value: 2, 
    amount: '', percentage: '', includeInUnitPrice: true, isNegative: false, isPercent: true},
  {label: 'Percent Discount', label2: 'This adjustment will decrease the unit price by the percentage entered.', description: '', value: 3, 
    amount: '', percentage: '', includeInUnitPrice: true, isNegative: true, isPercent: true}
];

const CASH_SALE_PRICE_ADJUSTMENT_OPTIONS = [
  constants.DEFAULT_PRICE_ADJUSTMENT_OPTION,
  {label: 'Side Fee (e.g. Freight)', label2: 'This fee will not change the unit price and is not taxed.', description: '', value: 1, 
    amount: '', percentage: '', includeInUnitPrice: false, isNegative: false, isPercent: false},
  ...COMMON_PRICE_ADJUSTMENT_OPTIONS
];
// TODO: consolidate to a single list once this is confirmed
const RTO_PRICE_ADJUSTMENT_OPTIONS = [
  constants.DEFAULT_PRICE_ADJUSTMENT_OPTION,
  {label: 'Side Fee (e.g. Freight)', label2: 'This fee will not change the unit price and is not taxed.', description: '', value: 1, 
    amount: '', percentage: '', includeInUnitPrice: false, isNegative: false, isPercent: false},
  ...COMMON_PRICE_ADJUSTMENT_OPTIONS
];

const getContractEditLists = (contractType) => {
  return api.fetch("contract/GetContractCreationData/" + contractType).then((r) => {
    return {
      dealerList: r.data.dealerList,
      regionList: r.data.regionList,
      cultureList: r.data.cultureList,
      widthList: r.data.widthList,
      heightList: r.data.heightList,
      productTypeList: r.data.productTypeList,
      dueDayList: r.data.dueDayList,
      // salespeopleList: r.data.salespeople,
      buildingOrientationList: r.data.buildingOrientationList,
      manufacturerList: r.data.manufacturerList,
      employmentTypeList: r.data.employmentTypeList,
      lengthList: r.data.lengthList,
    };
  });
};

const PHASE_NAMES = {
  CUSTOMER: "Customer Information",
  RELATIONS: "Customer Relations",
  UNITS: "Unit Information",
  BUILD: "Build",
  PRICING: "Pricing",
  PAYMENT: "Payment",
  QUOTE_PRICING: "Quote Pricing",
};

const CONTRACT_PHASE_LIST = [
  PHASE_NAMES.CUSTOMER,
  PHASE_NAMES.RELATIONS,
  PHASE_NAMES.UNITS,
  PHASE_NAMES.PRICING,
  PHASE_NAMES.PAYMENT,
];
const CONTRACT_BUILD_PHASE_LIST = [
  PHASE_NAMES.CUSTOMER,
  PHASE_NAMES.RELATIONS,
  PHASE_NAMES.UNITS,
  PHASE_NAMES.BUILD,
  PHASE_NAMES.PRICING,
  PHASE_NAMES.PAYMENT,
];
const CASH_SALE_PHASE_LIST = [
  PHASE_NAMES.CUSTOMER,
  PHASE_NAMES.UNITS,
  PHASE_NAMES.PAYMENT,
];
const CASH_SALE_BUILD_PHASE_LIST = [
  PHASE_NAMES.CUSTOMER,
  PHASE_NAMES.UNITS,
  PHASE_NAMES.BUILD,
  PHASE_NAMES.PAYMENT,
];
const QUOTE_PHASE_LIST = [
  PHASE_NAMES.CUSTOMER,
  PHASE_NAMES.UNITS,
  //PHASE_NAMES.QUOTE_PRICING,
];

const ContractEdit = ({ contractType, convertFromQuote }) => {
  const { routeContractId, fromInventoryId } = useParams();
  function isRTO() {
    return contractType === constants.CONTRACT_TYPE.RTO;
  }

  function isQuote() {
    return contractType === constants.CONTRACT_TYPE.QUOTE;
  }

  const contractCompanyId = useContractStore(x => x.companyId);
  const contractRegionId = useContractStore(x => x.regionId);
  const contractDealerId = useContractStore(x => x.dealerId);
  const setCompanyId = useContractStore((x) => x.setCompanyId);
  const setRegionId = useContractStore((x) => x.setRegionId);
  const setDealerId = useContractStore((x) => x.setDealerId);

  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const [contractData, setContractData] = useState({});
  const [originalCompanyId, setOriginalCompanyId] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [contractId, setContractId] = useState(routeContractId || 0);
  const [lastPairedInventoryId, setLastPairedInventoryId] = useState(0);
  const [agreementNumber, setAgreementNumber] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSkuAddonOptions, setLoadingSkuAddonOptions] = useState(false);
  const [loadingContract, setLoadingContract] = useState(false);
  const [availableUnitOptions, setAvailableUnitOptions] = useState([]);
  const [updatingAddons, setUpdatingAddons] = useState(false);
  const [saving, setSaving] = useState(false);
  const [wasInitialCreation, setWasInitialCreation] = useState(window.wasInitialCreation ? true : false);  
  const [validationMessage, setValidationMessage] = useState(null);
  const [disableSale, setDisableSale] = useState(false);
  const [phase, setPhase] = useState(null);
  const [fieldWarnings, setFieldWarnings] = useState([]);
  const [cards, setCards] = useState([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState('');
  const [commentPrivate, setCommentPrivate] = useState(false);
  const [comments, setComments] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [dueDays, setDueDays] = useState([]);
  const [cultures, setCultures] = useState([]);
  const [widths, setWidths] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [heights, setHeights] = useState([]);
  const [unitPriceDisabled, setUnitPriceDisabled] = useState(false);
  const [salespeople, setSalespeople] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [roofColors, setRoofColors] = useState([]);
  const [trimColors, setTrimColors] = useState([]);
  const [baseColors, setBaseColors] = useState([]);
  const [subproductTypes, setSubproductTypes] = useState([]);
  const [subProductSkuOptions, setSubProductSkuOptions] = useState([]);
  const [buildingOrientations, setBuildingOrientations] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [downPaymentOverPayment, setDownPaymentOverPayment] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const [skipValidation, setSkipValidation] = useState(false);
  const [enableBuildFeature, setEnableBuildFeature] = useState(false);
  const [enableCustomSerialGeneration, setEnableCustomSerialGeneration] = useState(false);
  const [defaultUnitTypeId, setDefaultUnitTypeId] = useState(null);
  const [hideUnitType, setHideUnitType] = useState(false);
  const [unpairedInvList, setUnpairedInvList] = useState(null);
  const [lastDealerId, setLastDealerId] = useState(null);
  const [lastMfgId, setLastMfgId] = useState(null);
  const [priceAdjustments, setPriceAdjustments] = useState([]);
  const [pendingPriceAdjustment, setPendingPriceAdjustment] = useState(null);
  const [lastSubproductId, setLastSubproductId] = useState(null);
  const [pristineUnitIsNew, setPristineUnitIsNew] = useState(null);
  const [ownershipCompanyList, setOwnershipCompanyList] = useState([]);
  const [searchingUnpaired, setSearchingUnpaired] = useState(false);
  const [lastMfgUrl, setLastMfgUrl] = useState('');
  const [lastSkuAddonUrl, setLastSkuAddonUrl] = useState("");
  const [lastSkuUrl, setLastSkuUrl] = useState("");
  const [lastPromoCodeTermCheck, setLastPromoCodeTermCheck] = useState('');
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [savedAddons, setSavedAddons] = useState([]);
  const [isFirstLoadOfUnitOptions, setIsFirstLoadOfUnitOptions] = useState(true);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [currentUserHasContractCreatorRole, setCurrentUserHasContractCreatorRole] = useState(false);
  const [canManageComments, setCanManageComments] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [lastFetchedContractURL, setLastFetchedContractURL] = useState('');
  const [phaseList, setPhaseList] = useState(resolvePhaseList());
  const [attachmentList, setAttachmentList] = useState(null);
  const [autoShowEditor, setAutoShowEditor] = useState(false);

  const ContractCrumbs = ({ currentPhase, onSetPhase, ...otherProps }) => {
    let phaseLinks = [];
    for (let i = 0; i < phaseList.length; i++) {
      const phaseValid = assessValidity(phaseList[i]).length === 0;
      let linkEnabled = (isCompanyAdmin || isSystemAdmin)
        && (i === 0
          || !wasInitialCreation
          || !currentUserHasContractCreatorRole
          || phaseValid);
      // special logic to disable link for Build to force click of the Next button to create inventory
      if (linkEnabled && phaseList[i] === PHASE_NAMES.BUILD && !contractData.inventoryId) {
        linkEnabled = false;
      }
      let linkSpan = null;
      if (i === currentPhase) {
        linkSpan = (
          <div
            className="text-center"
            style={{ width: "90%", fontSize: "0.85rem" }}
          >
             {phaseList[i]} {phaseValid ? ui_helpers.greenCheck() : ui_helpers.redExclamation()}
          </div>
        );
      } else if (!linkEnabled) {
        linkSpan = (
          <div
            className="text-center"
            style={{
              width: "90%",
              color: "#999",
              paddingTop: "8px",
              fontSize: "0.85rem",
            }}
          >
            {phaseList[i]} {phaseValid ? ui_helpers.greenCheck() : null}
          </div>
        );
      } else {
        linkSpan = (
          <div
            className="btn btn-link text-center"
            style={{ width: "90%", fontSize: "0.85rem" }}
            onClick={() => onSetPhase(i)}
          >
            {phaseList[i]} {phaseValid ? ui_helpers.greenCheck() : null}
          </div>
        );
      }
      phaseLinks.push(
        <Col
          key={i}
          className={classnames({ "current-phase": i === currentPhase })}
        >
          {i > 1 ? (
            <FontAwesomeIcon
              icon="angle-right"
              size="2x"
              style={{ marginTop: "3px", float: "left" }}
            />
          ) : null}
          {linkSpan}
        </Col>
      );
    }
    return <Row className={isQuote() ? "breadcrumb-quote" : "breadcrumb"}>{phaseLinks}</Row>;
  };

    useEffect(() => {
      if (loadingPage) return;
      const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
      setIsSystemAdmin(isSysAdmin);
      const companyId = contractData?.companyId;
      setCompanyId(companyId); // zustand
      const mfgId = resolveManufacturerId();
      const dealerId = contractData && contractData.dealer
        ? contractData.dealer.value
        : null;
      const cpyAdmin = contractData && _.some(currentUser.roles, ro => (ro.companyId === contractData?.companyId)
        && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator || ro.typeOfUserRole === constants.ROLE_IDS.ContractManager));
      const canMngContract = ui_helpers.canManageContracts(currentUser, companyId, dealerId);
      const isManufacturerUser = _.some(currentUser.roles, ro => ro.manufacturerId === mfgId
        && (ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator
            || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager
            || ro.typeOfUserRole === constants.ROLE_IDS.Administrator));      
      const canDispatch = isSysAdmin
          || _.some(currentUser.roles, ro =>
              ((ro.manufacturerId === mfgId
                      || ro.companyId === companyId)
                  && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
                      || (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
              || ((ro.dealerId === dealerId)
                  && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
              || ((ro.companyId === companyId)
                  && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)));
      setCanManageComments(isSysAdmin || canMngContract || isManufacturerUser || canDispatch || ui_helpers.isStoreUser(currentUser, dealerId));
      setIsCompanyAdmin(cpyAdmin);
      setCurrentUserHasContractCreatorRole(
        _.some(currentUser.roles, ro => ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator));
    }, [loadingPage, contractData?.companyId, contractData?.dealer, contractData?.manufacturer])

  useEffect(() => {
    setUnitPriceDisabled(getUnitPriceDisabled());
  }, [phase])

  useEffect(() => {
    const dealerId = contractData.dealer 
      ? contractData.dealer.value
      : null;
    if (!dealerId || (dealerId === -1 && (!contractData.salesperson || contractData.salesperson.label !== constants.OTHER))) {
      setSalespeople([constants.OTHER_OPTION]);
      setContractData(oldData => ({
        ...oldData, 
        salesperson: constants.OTHER_OPTION
      }));
    } else if (dealerId > 0 && dealerId !== lastDealerId) {
      setLastDealerId(dealerId);
      api.fetch("contract/GetSalespeople/" + dealerId).then((r) => {
        let newSalesList = r.data;
        // some other entries might have been saved (incorrectly)
        if (_.some(newSalesList, x => x.label === constants.OTHER)) {
          const idx = _.findIndex(r.data, x => x.label === constants.OTHER);
          newSalesList[idx].value = constants.OTHER_ID;
        }
        if (!_.some(newSalesList, x => x.label === constants.OTHER)) {
          newSalesList.push(constants.OTHER_OPTION);
        }
        setSalespeople(newSalesList);
        const resolvedSalespersonName = contractData.salespersonName || contractData.otherSalesperson || '';
        let salespersonLabel = contractData.salesperson && contractData.salesperson.label !== constants.OTHER
          ? contractData.salesperson.label
          : resolvedSalespersonName;
        if (!salespersonLabel) {
          salespersonLabel = storage.getItem(constants.STORAGE_KEYS.LAST_SALESPERSON_LABEL);
        }
        const selectedItem = _.find(newSalesList, t => t.label === salespersonLabel);
        let changedData = {};
        if (selectedItem) {
          changedData.salesperson = selectedItem;
          if (selectedItem.label !== constants.OTHER) {
            changedData.otherSalesperson = "";
          }
        } else {
          changedData.salesperson = constants.OTHER_OPTION;
        }
        setContractData(oldData => ({
          ...oldData, 
          ...changedData
        }));
      }).catch((e) => console.error(e));
      if (contractType === constants.CONTRACT_TYPE.CASH_SALE) {
        api.post("cashsale/GetOwnershipCompanyOptions/" + dealerId, {}).then((r) => {
          setOwnershipCompanyList(r.data);
        }).catch((e) => console.error(e));
      }
    }
  }, [contractData.dealer])

  function getHasSku() {
    return contractData.subproductSku 
      && contractData.subproductSku.value 
      && contractData.subproductSku.value !== constants.OTHER_ID;
  }

  function getUnitPriceDisabled(hasSku = null) {
    if (!contractData) return false;
    if (contractData.inventoryId || lastPairedInventoryId || contractData.agreedPrice) return true;
    if (hasSku === null) {
      hasSku = getHasSku();
    }
    return (enableBuildFeature || isQuote()) && hasSku;
  }

  // function getUnitPriceDisabled() {
  //   if (!contractData) return false;
  //   console.log('value', phaseList[phase], contractData.agreedPrice)
  //   if (lastPairedInventoryId || contractData.agreedPrice) return true;
  //   return(enableBuildFeature || isQuote()) 
  //     && (contractData.subproductSku && contractData.subproductSku.value !== constants.OTHER_ID);
  // }

  function getCompanyPaymentTypes(companyId, dealerId) {
    let url = 'contract/companyAcceptsPayment/' + companyId;
    if (dealerId) {
      url += "/" + dealerId;
    }
    api.post(url, {}).then(r => {
      if (!r.data.success) {
        Alert.error('There was an error getting the payment types that this company accepts.');
        return;
      }
      let newPaymentTypes = constants.PAYMENT_TYPES.slice();
      if (!r.data.message.creditCards) 
        _.remove(newPaymentTypes, {value: constants.PAYMENT_TYPE_IDS.CREDIT_CARD});
      if (!r.data.message.achBank)
        _.remove(newPaymentTypes, {value: constants.PAYMENT_TYPE_IDS.ACH_DEBIT});
      if (!isSystemAdmin && !isCompanyAdmin)
        _.remove(newPaymentTypes, {value: constants.PAYMENT_TYPE_IDS.COMMITMENT});
      if (!isSystemAdmin && !r.data.message.enableRetained)
        _.remove(newPaymentTypes, {value: constants.PAYMENT_TYPE_IDS.RETAINED});
      setPaymentTypes(newPaymentTypes);
    }).catch(err => Alert.error(err));
  }

  function getSubProductSkuOptions() {
    if (window.isPairing) return;
    // console.log('fired SP change')
    if (!contractData.subproduct 
      || !contractData.subproduct.value 
      || contractData.subproduct.value === constants.OTHER_ID)
    { 
      setSubProductSkuOptions([constants.OTHER_OPTION]);
      setContractData(oldData => ({
        ...oldData, 
        subproductSku: constants.OTHER_OPTION
      }));
      return;
    }
    let url = "contract/GetMfgSubProductSkuOptions/" + contractData.subproduct.value;
    if (lastSkuUrl === url) return;
    setLastSkuUrl(url)
    api.fetch(url).then((r) => {
      let list = _.chain(r.data)
        .sortBy(['width', 'length'])
        .map(d => ({ ...d, value: d.id, label: d.displayName }))
        .value();
      list.unshift(constants.OTHER_OPTION);
      setSubProductSkuOptions(list);
      let changeData = {};
      const width = contractData.otherWidth === "" ? contractData.widthFeet : contractData.otherWidth
      const length = contractData.otherLength === "" ? contractData.lengthFeet : contractData.otherLength;
      const oldSku = contractData.unitManufacturerSubProductSkuId
        ? { label: contractData.unitManufacturerSubProductSkuName, value: contractData.unitManufacturerSubProductSkuId}
        : contractData.subproductSku;
      const selectedItem = oldSku
        ? _.find(list, t => t.value === oldSku.value)
        : _.find(list, t => t.width === width && t.length === length);
      if (selectedItem) {
        changeData.subproductSku = selectedItem;
      } else {
        changeData.subproductSku = constants.OTHER_OPTION;
        changeData.otherWidth = width;
        changeData.widthFeet = constants.OTHER_ID;
        changeData.otherLength = length;
        changeData.lengthFeet = constants.OTHER_ID;
      }
      setContractData(oldData => ({...oldData, ...changeData}));
    }).catch((err) => console.error("There was an issue fetching size options: " + err));
  }

  function transformAddOns(list, overrideSkuId = null) {
    const skuId = overrideSkuId
      ? overrideSkuId
      : contractData.subproductSku?.value;
    return _.map(list, u => {
      const resolvedPrice = skuId === constants.OTHER_ID
        ? u.standardPrice
        : u.addOnStandardPrice;
      const label = u.id === constants.SELECT_OPTION_ID || u.id === constants.OTHER_ID
        ? u.name
        : `${u.name} - ${ui_helpers.formatCurrency(resolvedPrice)}`;
      return {
        ...u,
        label: label,
        value: u.id,
        resolvedPrice: resolvedPrice
      };
    });
  }

  function resolveManufacturerId(context = null) {
    if (context === null) {
      context = contractData;
    }
    if (context.manufacturer && context.manufacturer.value) {
      return context.manufacturer.value;
    } else if (context.manufacturerBrandId) {
      return context.manufacturerBrandId;
    } else if (context.manufacturerId) {
      return context.manufacturerId;
    }
    return constants.OTHER_ID;
  }

  function mapAddOnList(list) {
    return _.map(list, d => {
      if (!d.addOnId) {
        d.addOnId = 0;
      }
      return d;
    });
  }

  function refreshPriceAndAddOns(saveFloorplanResult = null) {
    if (!contractData || !contractData.contractId) return;
    if (saveFloorplanResult && saveFloorplanResult.newPrice) {
      setContractData(oldData => ({
        ...oldData,
        unitPrice: saveFloorplanResult.newPrice
      }));
    } else {
      api.post(`contract/GetContractAddonWithPrice/` + contractData.contractId).then(res => {
        if (res.data && res.data.success) {
          setContractData(oldData => ({
            ...oldData, 
            unitPrice: res.data.message.price,
            contractAddOns: mapAddOnList(res.data.message.addOnList)
          }));
        }
      }).catch(err => console.error(err));      
    }
  }

  function onUpdateContractAddons(list, readOnly = false, newContractData = null) {
    setUpdatingAddons(true);
    const data = newContractData ? newContractData : contractData;
    const hasSku = data.subproductSku 
      && data.subproductSku.value 
      && data.subproductSku.value !== constants.OTHER_ID;
    const buildEffective = enableBuildFeature && data.unitIsNew && !data.unitIsInventory;
    const qt = isQuote();
    let payload = {
      ReadOnly: readOnly,
      ContractId: contractId,
      AddonOptionList: list,
      UpdatePrice: (qt || !buildEffective) && hasSku
    };
    if (data.inventoryId) {
      payload.inventoryId = data.inventoryId;
    }
    if (hasSku) {
      payload.manufacturerSubProductSkuId = data.subproductSku.value;
    }
    api.post("contract/UpdateContractAddonOption", payload).then(res => {
      if (!res.data || (res.data && !res.data.success)) {
        console.error("There was an error updating contract addons")
        return null;
      }
      setContractData(oldData => {
        let unitPriceDisabled = false;
        const oldDataHasSku = oldData.subproductSku 
          && oldData.subproductSku.value 
          && oldData.subproductSku.value !== constants.OTHER_ID;
        const oldDataBuildEffective = enableBuildFeature && oldData.unitIsNew && !oldData.unitIsInventory;
        if (lastPairedInventoryId) {
          unitPriceDisabled = true;
        } else {
          unitPriceDisabled = (enableBuildFeature || qt) 
            && oldDataHasSku;
        }
        const shouldUpdatePrice = (qt || !oldDataBuildEffective) && oldDataHasSku;
        // console.log(unitPriceDisabled, shouldUpdatePrice, oldDataBuildEffective, lastPairedInventoryId, 
        //   oldData.subproductSku?.value, enableBuildFeature, oldData.unitIsNew, !oldData.unitIsInventory)
        const newPrice = shouldUpdatePrice || unitPriceDisabled
          ? res.data.message.price
          : oldData.unitPrice;
        const newAddOns = mapAddOnList(res.data.message.addOnList);
        let changedProperties = {
          unitPrice: newPrice,
          contractAddOns: newAddOns
        };
        if (payload.inventoryId) {
          changedProperties.inventoryId = payload.inventoryId;
        }
        // console.log('updated unitprice after updateaddons to', changedProperties)
        return {
          ...oldData, 
          ...changedProperties
        };
      });
      if (_.some(savedAddons)) {
        setSavedAddons([]);
      }
    })
    .catch(er => console.error(er))
    .finally(() => setUpdatingAddons(false));
  }

  function getMfgSubproductSkuAddOnOptions() {
    if (loadingSkuAddonOptions || loadingPage || window.isPairing) return;
    let url = '';
    const mfgId = resolveManufacturerId();
    if (!mfgId
      || mfgId === constants.OTHER_ID
      || !contractData.subproductSku
      || !contractData.subproductSku.value
    )
    {
      setAvailableUnitOptions([
        { value: constants.SELECT_OPTION_ID, label: "Select Option" },
        constants.OTHER_OPTION
      ]);
      setLastSkuAddonUrl("");
    } else if (contractData.subproductSku 
      && contractData.subproductSku.value === constants.OTHER_ID 
      && mfgId !== constants.OTHER_ID) 
    {
      url = 'contract/ListManufacturerAddons';
      const urlId = `${url}/${mfgId}`;
      if (lastSkuAddonUrl === urlId) return;
      setLastSkuAddonUrl(urlId);
      api.post(url, { activeOnly: true, id: mfgId }).then(r => {
        if (!r.data.success) return;
        let list = r.data.message;
        if (!_.some(list, l => l.name === constants.OTHER)) {
          list.unshift(getOtherAddOnOption());
        }
        setAvailableUnitOptions(transformAddOns(list));
      }).catch(api.catchHandler);
    }
    else if (contractData.subproductSku && contractData.subproductSku.value !== constants.OTHER_ID) 
    {
      url = `inventory/GetMfgProductSkuAddOnAndDefaultOptions/${mfgId}/${contractData.subproductSku.value}`;
      if (!window.isPairing) {
        // indication that we need to perform an insert/update to apply default sku add-ons for this contract and related inventory
        url += "/" + contractId;
        if (convertFromQuote) {
          url += "/true";
        }
      }
      if (loadingSkuAddonOptions || url === lastSkuAddonUrl) return;
      setLoadingSkuAddonOptions(true);
      api.fetch(url).then(r  => {
        if (!r.data) return;
        setLastSkuAddonUrl(url);
        setAvailableUnitOptions(transformAddOns(r.data.mfgUnitOptions));
        if (window.isPairing) return;
        const notAQuote = !isQuote();
        if (_.some(savedAddons) && (notAQuote || isFirstLoadOfUnitOptions)) {
          if (isFirstLoadOfUnitOptions) {
            setIsFirstLoadOfUnitOptions(false);
          } else {
            onUpdateContractAddons(savedAddons);            
          }
        } else {
          const defaultAddons = _.map(r.data.mfgDefaultSkuTemplateOptions, x => ({
            addOnId: x.addOnId,
            addOnTypeId: x.addOnTypeId,
            bundleId: x.bundleId,
            description: "",
            hasDimensions: true,
            id: 0,
            length: x.length,
            name: x.addOnName,
            typeOfUnitOfMeasure: x.unitOfMeasureId,
            price: x.price,
            // estimatedPrice: x.estimatedPrice,
            seq: 1,
            showInFloorPlan: true,
            width: x.width,
            isDefaultAddon: true,
            positionX: x.positionX,
            positionY: x.positionY,
            rotation: x.rotation,
            uniqueAddOnId: x.uniqueAddOnId,
            swingTypeId: x.typeOfSwing || 0,
            additionalOption1On: x.additionalOption1On,
            additionalOption2On: x.additionalOption2On,
            additionalOption1Price: x.additionalOption1Price,
            additionalOption2Price: x.additionalOption2Price,
            additionalOption1Type: x.additionalOption1Type,
            additionalOption2Type: x.additionalOption2Type
          }));
          onUpdateContractAddons(defaultAddons, notAQuote);
        }
      }).catch(err => console.error(err))
      .finally(()=> setLoadingSkuAddonOptions(false));
    }
  }

  function refreshProductTypeList() {
    if (!contractData.dealer || window.isPairing || loadingPage) return;
    const mfgId = resolveManufacturerId();
    if (mfgId === constants.OTHER_ID) 
    {
      const fullList = _.reject(constants.PRODUCT_TYPES, x => x.value === constants.SELECT_OPTION_ID);
      if (productTypes.length !== fullList.length) {
        setProductTypes(fullList);
      }
      if (!contractData.productType) {
        setContractData(oldData => ({
          ...oldData, 
          productType: constants.OTHER_OPTION
        }));        
      }
    }
    if (!mfgId || mfgId === constants.OTHER_ID || mfgId === lastMfgId) return;
    setLastMfgId(mfgId);
    api.fetch(`contract/getFilteredProductTypes/${mfgId}`).then(r => {
      if (r.data.success && r.data.message && r.data.message.length) {
        let list = ui_helpers.idNameToValueLabel(r.data.message, null, false);
        setProductTypes(list);
        if (contractData.productType && !_.some(list, x => x.value === contractData.productType.value))
        {
          setContractData(oldData => ({
            ...oldData, 
            productType: constants.OTHER_OPTION
          }));
        }
      }
    }).catch(api.catchHandler);
  }

  function resetColors() {
    setTrimColors([constants.OTHER_OPTION]);
    setBaseColors([constants.OTHER_OPTION]);
    setRoofColors([constants.OTHER_OPTION]);
  }

  function onChangeMfgOrProductType(newContractData = null, loadedExistingManufacturerId = null) {
    if ((loadingPage || loadingContract || window.isPairing) && !newContractData) return;
    // console.log('fired MF/PT change')
    const resolvedData = newContractData
      ? newContractData
      : contractData;
    let changedData = newContractData
      ? {...contractData, ...newContractData}
      : {...contractData};
    setUnitPriceDisabled(getUnitPriceDisabled());
    const mfgId = resolveManufacturerId(resolvedData);
    if (!mfgId || !resolvedData.productType || !resolvedData.productType.value) {
      if (newContractData) {
        setContractData(oldData => ({
          ...oldData, 
          ...newContractData
        }));
      }
      if (mfgId === constants.OTHER_ID
        && (!changedData.productType || changedData.productType.value !== constants.OTHER_ID)) {
        resetColors();
        setSubproductTypes([constants.OTHER_OPTION]);
        setUnitTypes([constants.OTHER_OPTION]);
        setEnableBuildFeature(false);
        setEnableCustomSerialGeneration(false);
        changedData.productType = constants.OTHER_OPTION;
        changedData.subproduct = constants.OTHER_OPTION;
        changedData.unitType = constants.OTHER_OPTION;
        changedData.roofColor = constants.OTHER_OPTION;
        changedData.trimColor = constants.OTHER_OPTION;
        changedData.baseColor = constants.OTHER_OPTION;
        changedData.subproductSku = constants.OTHER_OPTION;
        changedData.otherWidth = changedData.otherWidth === "" ? changedData.widthFeet : changedData.otherWidth;
        changedData.otherLength = changedData.otherLength === "" ? changedData.lengthFeet : changedData.otherLength;
        setContractData(changedData);
      }
      return;
    }
    const url = `contract/GetManufacturerLists/${mfgId}/${resolvedData.productType.value}`;
    if (lastMfgUrl === url) {
      setContractData(changedData);
      return;
    }
    api.fetch(url).then((r) => {
      setLastMfgUrl(url);
      const changedUnitTypes = ui_helpers.idNameToValueLabel(r.data.unitTypeList, null, false);
      const changedSubproductTypes = ui_helpers.idNameToValueLabel(r.data.subproductTypeList);
      const changedBaseColors = ui_helpers.idNameToValueLabel(r.data.baseColorList);
      const changedTrimColors = ui_helpers.idNameToValueLabel(r.data.trimColorList);
      const changedRoofColors = ui_helpers.idNameToValueLabel(r.data.roofColorList);
      // if there is a subproduct allow the more specific call to load these lists
      if (!contractData.subproduct || contractData.subproduct.value === constants.OTHER_ID) {
        setBaseColors(changedBaseColors);
        setTrimColors(changedTrimColors);
        setRoofColors(changedRoofColors);
      }
      setSubproductTypes(changedSubproductTypes);
      if (changedUnitTypes) setUnitTypes(changedUnitTypes);
      if (r.data.defaultUnitTypeId) setDefaultUnitTypeId(r.data.defaultUnitTypeId)
      setHideUnitType(r.data.hideUnitType)
      // we only set this based on the manufacturing setting here
      // if it has not already been determined by an existing, saved relationship from a paired inventory
      if (!resolvedData.inventoryId && loadedExistingManufacturerId !== mfgId) { //  && data.productType.value > 0
        setEnableBuildFeature(r.data.enableBuildFeature);
      }
      setEnableCustomSerialGeneration(r.data.enableCustomSerialGeneration);
      ui_helpers.resolveListEntryPropsByText(changedData, 'subproduct', 'otherSubproduct', changedSubproductTypes, 'unitManufacturerSubproductText');
      ui_helpers.resolveListEntryPropsByText(changedData, 'unitType', 'otherUnitType', changedUnitTypes);
      ui_helpers.resolveListEntryPropsByText(changedData, 'roofColor', 'otherRoofColor', changedRoofColors, 'unitManufacturerRoofColorText');
      ui_helpers.resolveListEntryPropsByText(changedData, 'trimColor', 'otherTrimColor', changedTrimColors, 'unitManufacturerTrimColorText');
      ui_helpers.resolveListEntryPropsByText(changedData, 'baseColor', 'otherBaseColor', changedBaseColors, 'unitManufacturerBaseColorText');
      changedData.subproductSku = changedData.unitManufacturerSubProductSkuId 
        ? { label: changedData.unitManufacturerSubProductSkuName, value: changedData.unitManufacturerSubProductSkuId}
        : changedData.subproductSku
          ? changedData.subproductSku
          : constants.OTHER_OPTION;
      if (changedData.subproductSku.value === constants.OTHER_ID) {
        changedData.otherWidth = changedData.otherWidth === "" ? changedData.widthFeet : changedData.otherWidth;
        changedData.otherLength = changedData.otherLength === "" ? changedData.lengthFeet : changedData.otherLength;
      }
      const resolvedUnitTypes = changedUnitTypes ? changedUnitTypes : unitTypes;
      if (r.data.hideUnitType 
        && r.data.defaultUnitTypeId
        && (!resolvedData.unitType || resolvedData.unitType.value !== r.data.defaultUnitTypeId)
        && _.some(resolvedUnitTypes, ut => ut.value === r.data.defaultUnitTypeId)) {
        changedData.unitType = _.find(resolvedUnitTypes, ut => ut.value === r.data.defaultUnitTypeId);
      }
      applyPhaseList();
      setContractData(changedData);
    })
    .catch(api.catchHandler);
  }

  useEffect(() => {
    if (!lastFetchedContractURL) return;
    setLoadingContract(true);
    api.fetch(lastFetchedContractURL).then((r) => {
      if (!r.data.success) {
        setValidationMessage({ message: r.data.message, flavor: 'danger'});
        setLastFetchedContractURL('');
        setTimeout(() => setValidationMessage(null), 4000);
        return;
      }
      const c = r.data.message.contract;
      const newDealer = { value: c.dealerId, label: c.dealerName };
      let resolvedSubProduct = null;
      if (c.unitManufacturerSubproductId === constants.OTHER_ID) {
        resolvedSubProduct = constants.OTHER_OPTION;
      } else if (c.unitManufacturerSubproductId) {
        resolvedSubProduct = {value: c.unitManufacturerSubproductId, label: c.unitManufacturerSubproductText};
      }
      let resolvedSubProductSku = null;
      if (c.unitManufacturerSubProductSkuId === constants.OTHER_ID) {
        resolvedSubProductSku = constants.OTHER_OPTION;
      } else if (c.unitManufacturerSubProductSkuId) {
        resolvedSubProductSku = {value: c.unitManufacturerSubProductSkuId, label: c.unitManufacturerSubProductSkuName};
      }
      const resolvedSalespersonName = c.salespersonName === constants.OTHER
        ? ''
        : (c.salespersonName || c.otherSalesperson);
      const resolvedSalesperson = constants.OTHER_OPTION;
      const resolvedBuildingOrientation = c.deliveryBuildingOrientation
        ? _.find(buildingOrientations, b => b.label === c.deliveryBuildingOrientation)
        : null;
      const taxExemptForm = _.find(r.data.message.attachmentList, (a) => a.typeOfAttachment === constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm);
      let uiData = {
        ...c,
        firstName: c.customerFirstName,
        lastName: c.customerLastName,
        customerPhone1: c.customerPhone1,
        secondaryPhone: c.customerPhone2,
        email: c.customerEmail,
        ssn: ui_helpers.formatSSNField(c.customerSSN),
        licenseNo: c.customerLicenseNumber,
        dateOfBirth: ui_helpers.formatDateField(c.customerDateOfBirth),
        dealer: newDealer,
        region: {
          value: c.regionId,
          label: c.regionName,
          abbr: c.regionAbbr,
        },
        culture: { value: c.cultureId, label: c.cultureName },
        deliveryName: c.deliveryName,
        deliveryAddress1: c.deliveryAddress1,
        deliveryAddress2: c.deliveryAddress2,
        deliveryCity: c.deliveryCity,
        deliveryState: c.deliveryState,
        deliveryZip: c.deliveryZip,
        billingName: c.billingName,
        billingAddress1: c.billingAddress1,
        billingAddress2: c.billingAddress2,
        billingCity: c.billingCity,
        billingState: c.billingState,
        billingZip: c.billingZip,
        companyId: c.companyId,
        propertyOwner: c.isPropertyOwner,
        landOwnerFirstName: c.landownerFirstName,
        landOwnerLastName: c.landownerLastName,
        landOwnerAddress1: c.landownerAddress1,
        landOwnerAddress2: c.landownerAddress2,
        landOwnerCity: c.landownerCity,
        landOwnerState: c.landownerState,
        landOwnerZip: c.landownerZip,
        landOwnerPhone: c.landownerPhone,
        landOwnerEmail: c.landownerEmail,
        employmentType: {
          value: c.customerEmploymentTypeId,
          label: c.customerEmploymentTypeName,
        },
        employerName: c.customerEmployerName,
        employerPhone: c.customerEmployerPhone,
        corenterFirstName: c.corenterFirstName,
        corenterLastName: c.corenterLastName,
        corenterAddress1: c.corenterAddress1,
        corenterAddress2: c.corenterAddress2,
        corenterCity: c.corenterCity,
        corenterState: c.corenterState,
        corenterZip: c.corenterZip,
        corenterDateOfBirth: c.corenterDateOfBirth,
        corenterPhone: c.corenterPhone,
        corenterEmail: c.corenterEmail,
        serialNumber: c.serialNumber,
        unitPrice: parseFloat(c.unitPrice).toFixed(2),
        //pristineUnitPrice: c.unitPrice,
        manufacturer: !c.manufacturerId 
          ? constants.OTHER_OPTION
          : { value: c.manufacturerId, label: c.manufacturerName },
        otherManufacturer: c.manufacturerOtherText,
        productType: { value: c.productTypeId, label: c.productTypeName },
        inventoryId: c.inventoryId,
        widthFeet: c.unitWidthFeet,
        lengthFeet: c.unitLengthFeet,
        heightFeet: c.unitHeightFeet,
        otherWidth: c.unitWidthFeetOtherText,
        otherLength: c.unitLengthFeetOtherText,
        otherHeight: c.unitHeightFeetOtherText,
        unitIsNew: c.unitIsNew,
        buildingOrientation: resolvedBuildingOrientation,
        estimatedDeliveryDate: c.estimatedDeliveryDate
          ? moment(c.estimatedDeliveryDate)
          : null,
        salesperson: resolvedSalesperson,
        otherSubproduct: c.unitManufacturerSubproductText,
        otherUnitType: c.unitTypeName,
        otherRoofColor: c.unitManufacturerRoofColorText,
        otherTrimColor: c.unitManufacturerTrimColorText,
        otherBaseColor: c.unitManufacturerBaseColorText,
        otherSalesperson: resolvedSalespersonName,
        unitManufacturerSubproductId: c.unitManufacturerSubproductId,
        unitManufacturerSubproductName: c.unitManufacturerSubproductName,
        unitManufacturerSubProductSkuId: c.unitManufacturerSubProductSkuId,
        unitManufacturerSubProductSkuName: c.unitManufacturerSubProductSkuName,
        subproduct: resolvedSubProduct,
        subproductSku: resolvedSubProductSku,
        unitType: { value: c.unitTypeId, label: c.unitTypeName },
        baseColor: constants.OTHER_OPTION, 
        roofColor: constants.OTHER_OPTION,
        trimColor: constants.OTHER_OPTION,
        deliveryInstructions: c.deliveryInstructions,
        unitIsInventory: c.soldFromInventory,
        contractAddOns: c.contractAddOns,
        taxRateString: ui_helpers.taxRateStringFromTaxRate(c.taxRate),
        taxRate: c.taxRate,
        isTaxExempt: c.isTaxExempt,
        taxExemptionForm: taxExemptForm || null,
        // taxAmount: ,
        //freightAmount: c.freightAmount
          // ? parseFloat(c.freightAmount).toFixed(2)
          // : 0,
        //for rto value is saved as negative number
        // otherAmount: isRTO() ? (c.otherAmount * -1) : c.otherAmount,
        // otherAmountCaption: c.otherAmountCaption,
        // subtotal:
        ldwAmount: c.damageLiabilityWaiverAmount,
        optedOutOfDamageLiabilityWaiver: c.optedOutOfDamageLiabilityWaiver,
        paymentDueDayOfMonth: c.paymentDueDayOfMonth,
        initialPaymentAmount: parseFloat(c.initialPaymentAmount).toFixed(2),
        includedInitialPayment: c.initialPaymentAmount ? true : false,
        selectedTermInfo: {
          id: c.typeOfContractTerm,
          monthlyPaymentAmount: c.monthlyPaymentAmount,
          typeOfContractTerm: c.typeOfContractTerm,
          contractTermId: c.contractTermId,
          dlwAmount: c.dlwAmount,
          minimumSecurityDepositAmount: c.minimumSecurityDepositAmount,
          dueForDeliveryAmount: c.requiredForDeliveryAmount,
          rentalCostAmount: c.totalRentalAmount,
          purchaseReserveAmount: c.purchaseReserveAmount,
        },
        promotionCode: c.promotionCode,
        promotionCodeTemp: c.promotionCode,
        enableRecurring: c.enableRecurringPayments,
        refreshCount: 0,
        bundlesToDelete: [],
        agreedPrice: c.agreedPrice
      };
      setLastPairedInventoryId(0);
      delete uiData.priceAdjustments;
      setPriceAdjustments(c.priceAdjustments);
      setEnableBuildFeature(c.existingBuildFeature);
      if (r.data.message.additionalContacts && r.data.message.additionalContacts.length) {
        let additionalContacts = r.data.message.additionalContacts;
        for (let i = 0; i < additionalContacts.length; i++) {
          if (!additionalContacts[i].firstName) break;
          uiData[`contact${i + 1}FirstName`] =
              additionalContacts[i].firstName;
          uiData[`contact${i + 1}LastName`] =
              additionalContacts[i].lastName;
          uiData[`contact${i + 1}Phone`] = additionalContacts[i].phone;
          uiData[`contact${i + 1}Email`] = additionalContacts[i].email;
          uiData[`contact${i + 1}Relationship`] = additionalContacts[i].relationshipToCustomer;
        }
      }
      setPristineUnitIsNew(c.unitIsNew);
      setSavedAddons(r.data.message.unitOptionList)
      setPaymentList(r.data.message.paymentItems);
      //setPristineUnitPrice(uiData.unitPrice);
      getCompanyPaymentTypes(c.companyId, c.dealerId);
      setCards(
        _.map(r.data.message.ccList, (cc) => ({
          value: cc.id,
          label: cc.secureDisplay,
        })));
      setComments(r.data.message.commentList);
      setOwnershipCompanyList(r.data.message.ownershipCompanyList);
      if (window.wasInitialCreation && contractType === constants.CONTRACT_TYPE.CASH_SALE) {
        // force intentional selection of companyid for cash sale initial load
        setOriginalCompanyId(c.companyId);
        uiData.companyId = null;
        setWasInitialCreation(true);
        delete window.wasInitialCreation;
      } else {
        setCompanyId(c.companyId); // zustand
        setRegionId(c.regionId); // zustand
        setDealerId(c.dealerId); // zustand
      }
      setAttachmentList(r.data.message.attachmentList);
      if (!_.some(dealers, d => d.value === newDealer.value)) {
        setDealers([newDealer, ...dealers]);
      }
      onChangeMfgOrProductType(uiData, uiData.manufacturer.value);
    }).catch((err) => console.error(err))
    .finally(() => setLoadingContract(false));
  }, [lastFetchedContractURL])

  function loadContract(id = null) {
    if (loadingContract) return;
    const cId = !id ? contractId : id;
    if (!cId) return;
    const controllerName = isRTO() ? "contract" : "cashSale";
    setLastFetchedContractURL(controllerName + "/" + cId);
  }

  function addPriceAdjustment(pa) {
    let payload = {
      contractId: routeContractId,
      description: pa.description,
      amount: pa.amount,
      includeInUnitPrice: pa.includeInUnitPrice
    };
    if (pa.percentage && parseFloat(pa.percentage)) {
      const pct = parseFloat(pa.percentage);
      payload.percentage = pct / 100.0;
    }
    api.post('contract/addPriceAdjustment', payload).then(r => {
      if (r.data.success) {
        setPendingPriceAdjustment(null);
        setPriceAdjustments(r.data.message);
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function deletePriceAdjustment(paId) {
    let payload = {
      contractId: routeContractId,
      priceAdjustmentId: paId
    };
    api.post('contract/deletePriceAdjustment', payload).then(r => {
      if (r.data.success) {
        setPriceAdjustments(
          _.reject(priceAdjustments, pa => pa.id === paId));
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function submitComment() {
    api.post('contract/addComment', {
      id: routeContractId,
      comment: comment,
      isPrivate: commentPrivate
    }).then(r => {
      if (r.data.success) {
        setComment('');
        setShowCommentModal(false);
        refreshComments();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function refreshComments() {
    api.fetch(`contract/GetComments/${contractId}`).then(r => {
      setComments(r.data);
    });
  }

  function deleteComment(commentId) {
    api.post('contract/deleteComment', { id: commentId }).then(r => {
      if (r.data.success) {
        refreshComments();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function resolvePhaseList() {
    if (isQuote()) {
      return QUOTE_PHASE_LIST;
    } else if (enableBuildFeature && contractData?.unitIsNew && !contractData?.unitIsInventory) {
      if (isRTO()) {
        return CONTRACT_BUILD_PHASE_LIST;
      } else {
        return CASH_SALE_BUILD_PHASE_LIST;
      }
    } else if (isRTO()) {
      return CONTRACT_PHASE_LIST;
    }
    return CASH_SALE_PHASE_LIST;
  }

  function applyPhaseList() {
    setPhaseList(resolvePhaseList());
  }

  useEffect(applyPhaseList, [contractType, enableBuildFeature, contractData.unitIsInventory, contractData.unitIsNew])

  function initialLoad() {
    if (loadingPage) return;
    setLoadingPage(true);
    getContractEditLists(contractType).then(
      ({
        dealerList,
        defaultDealerId,
        regionList,
        cultureList,
        widthList,
        lengthList,
        heightList,
        dueDayList,
        productTypeList,
        employmentTypeList,
        buildingOrientationList,
        manufacturerList,
    }) => {
      let newContractData = _.cloneDeep(contractData);
      setDealers(dealerList);
      if (
        defaultDealerId &&
        _.some(dealerList, (x) => x.value === defaultDealerId) &&
        !contractId
      ) {
        newContractData.dealer = _.find(
          dealerList,
          (x) => x.value === defaultDealerId
        );
      }
      const ptList = _.map(productTypeList, (pt) => ({
        ...pt,
        value: pt.productTypeId,
        label: pt.productTypeName,
      }));
      setRegions(ui_helpers.idNameToValueLabel(regionList));
      setDueDays(ui_helpers.idNameToValueLabel(dueDayList, null, false));
      setCultures(cultureList);
      setWidths(widthList);
      setLengths(lengthList);
      setHeights(_.reject(heightList, (o) => !o.label));
      setProductTypes(ptList);
      setEmploymentTypes(employmentTypeList);
      setBuildingOrientations(
        _.reject(buildingOrientationList, (o) => !o.label)
      );
      manufacturerList.unshift(constants.OTHER_OPTION);
      setManufacturers(manufacturerList);
      setPhase(0);
      // set default values if we aren't loading an existing contract
      if (contractId) {
        setContractData(newContractData);
        loadContract();
      } else {
        const lastMfgId = parseInt(
          storage.getItem(constants.STORAGE_KEYS.LAST_MFG_ID),
          10
        );
        if (
          lastMfgId &&
          _.some(manufacturerList, (x) => x.value === lastMfgId)
        ) {
          newContractData.manufacturer = _.find(
            manufacturerList,
            (x) => x.value === lastMfgId
          );
        }
        const lastRegionId = parseInt(storage.getItem(constants.STORAGE_KEYS.LAST_REGION_ID), 10);
        if (!newContractData.dealer && dealerList.length) {
          newContractData.dealer = dealerList[0];
        }
        if (lastRegionId && !newContractData.region && _.some(regionList, (x) => x.value === lastRegionId)) {
          newContractData.region = _.find(regionList, (r) => r.value === lastRegionId);
        }
        if (!newContractData.employmentType && employmentTypeList.length) {
          newContractData.employmentType = _.find(
            employmentTypeList,
            (t) => t.label === "Employed"
          );
        }
        if (!newContractData.culture) {
          newContractData.culture = _.find(
            cultureList,
            (c) => c.value === constants.CULTURE_ENGLISH_ID
          );
        }
        if (
          !newContractData.productType &&
          ptList.length &&
          _.some(
            ptList,
            (x) => x.value === constants.PRODUCT_TYPE_IDS.STORAGE_BUILDING
          )
        ) {
          newContractData.productType = _.find(
            ptList,
            (t) => t.value === constants.PRODUCT_TYPE_IDS.STORAGE_BUILDING
          );
        }
        if (!newContractData.widthFeet) {
          newContractData.widthFeet = 6;
        }
        if (!newContractData.lengthFeet) {
          newContractData.lengthFeet = 8;
        }
        if(!newContractData.associatedBundles) {
          newContractData.associatedBundles = [];
        }
        if(!newContractData.contractAddOns) {
          newContractData.contractAddOns = [];
        }
        setContractData(newContractData);
        const qt = isQuote();
        const url = isRTO()
            ? "contract/InitializeContract"
            : `cashSale/InitializeCashSale/${qt}`;
        api.post(url, {id: lastRegionId || 0}).then((r) => {
          if (r.data.success) {
            const editUrl = qt
                ? constants.PATH_NAMES.QUOTE_EDIT
                : isRTO() 
                    ? constants.PATH_NAMES.CONTRACT_EDIT
                    : constants.PATH_NAMES.CASH_SALE_EDIT;
            history.push(editUrl + '/' + r.data.message.contractId);
            setContractId(r.data.message.contractId);
            setAgreementNumber(r.data.message.agreementNumber);
            window.wasInitialCreation = true;
            setWasInitialCreation(true);
            loadContract(r.data.message.contractId);
          } else {
            setValidationMessage({ message: r.data.message, flavor: "warning" });
          }
        }).catch((err) => console.error(err));
      }
    })
    .catch((e) => console.error(e))
    .finally(() => setLoadingPage(false));
  }

  useEffect(initialLoad, []);
  useEffect(refreshProductTypeList, [loadingPage, contractData?.manufacturer])
  useEffect(onChangeMfgOrProductType, [loadingPage, contractData?.manufacturer, contractData?.productType])
  useEffect(() => {
    // if the quote is a new version, do not set unitIsNew or unitIsInventory to null
    if (wasInitialCreation && !contractData.inventoryId && contractData.agreementNumber == 'Quote') {
      setContractData((p) => ({...p}));
    }
    else if (wasInitialCreation && !contractData.inventoryId) {
      // always force selection for these 2 fields upon initial creation
      setContractData((p) => ({
        ...p,
        unitIsNew: null,
        unitIsInventory: null
      }));
    }
  }, [contractData?.manufacturer])

  useEffect(() => {
    getSubProductSkuOptions();
    getSubProductColors();
  }, [contractData?.subproduct])
  useEffect(() => {
    if (loadingSkuAddonOptions || loadingPage) return;
    getMfgSubproductSkuAddOnOptions();
  }, [loadingPage, contractData?.manufacturer, contractData?.subproductSku?.value])
  useEffect(getUnpairedInventory, [unpairedInvList?.pageNumber])

  function getOtherAddOnOption() {
    return {
      id: constants.OTHER_ID,
      value: constants.OTHER_ID,
      name: constants.OTHER,
      label: constants.OTHER,
      addOnId: 0,
      addOnLength: 0,
      addOnName: null,
      addOnStandardPrice: 0,
      standardPrice: 0,
      addOnWidth: 0,
      deactivatedAt: null,
      defaultPrice: 0,
      manufacturerId: 0,
      manufacturerName: null,
      manufacturerSubProductId: 0,
      manufacturerSubProductSkuBasePrice: 0,
      manufacturerSubProductSkuId: 0,
      manufacturerSubProductSkuLength: 0,
      manufacturerSubProductSkuWidth: 0
    };
  }

  function loadPairedInventoryLists(mfgId, productTypeId, subproductId, skuId, newUnitPrice, newInventoryId) {
    let skuOptions = subProductSkuOptions.slice();
    let spOptions = subproductTypes.slice();
    let ptOptions = productTypes.slice();
    let bcOptions = baseColors.slice();
    let trimOptions = trimColors.slice();
    let roofOptions = roofColors.slice();
// refreshProductTypeList();
    return new Promise((resolve, reject) => {
      let newContractData = { ...contractData };
      if (mfgId === newContractData.manufacturer?.value && productTypeId === newContractData.productType?.value) {
        resolve(newContractData);
      } else {
        // console.log('Updating paired MFG', mfgId, newContractData.manufacturer?.value);
        if (mfgId === constants.OTHER_ID && (!productTypeId || productTypeId === constants.OTHER_ID)) {
          ptOptions = constants.PRODUCT_TYPES;
          setProductTypes(ptOptions);
          newContractData.productType = constants.OTHER_OPTION;
        }
        if (mfgId && mfgId !== constants.OTHER_ID) {
          setLastMfgId(mfgId);
          api.fetch(`contract/getFilteredProductTypes/${mfgId}`).then(r => {
            if (r.data.success && r.data.message && r.data.message.length) {
              ptOptions = ui_helpers.idNameToValueLabel(r.data.message, null, false);
              setProductTypes(ptOptions);
              if (productTypeId && !_.some(ptOptions, x => x.value === productTypeId)) {
                newContractData.productType = constants.OTHER_OPTION;
              }
              resolve(newContractData);
            } else {
              reject();
            }
          }).catch(api.catchHandler);
        } else {
          resolve(newContractData);
        }
      }
    })
    .then(newContractData => {
      if (mfgId === newContractData.manufacturer?.value && productTypeId === newContractData.productType?.value) {
        return newContractData;
      } else {
        // console.log('Updating paired PT', productTypeId, newContractData.productType?.value);
        if (!mfgId || !productTypeId) {
          if (mfgId === constants.OTHER_ID && (!productTypeId || productTypeId !== constants.OTHER_ID)) {
            resetColors();
            spOptions = [constants.OTHER_OPTION];
            setSubproductTypes(spOptions);
            setUnitTypes([constants.OTHER_OPTION]);
            setEnableBuildFeature(false);
            setEnableCustomSerialGeneration(false);
            newContractData.productType = constants.OTHER_OPTION;
            newContractData.subproduct = constants.OTHER_OPTION;
            newContractData.unitType = constants.OTHER_OPTION;
            newContractData.roofColor = constants.OTHER_OPTION;
            newContractData.trimColor = constants.OTHER_OPTION;
            newContractData.baseColor = constants.OTHER_OPTION;
            newContractData.subproductSku = constants.OTHER_OPTION;
            newContractData.otherWidth = newContractData.otherWidth === "" ? newContractData.widthFeet : newContractData.otherWidth;
            newContractData.otherLength = newContractData.otherLength === "" ? newContractData.lengthFeet : newContractData.otherLength;
          }
          return newContractData;
        } else {
          const url = `contract/GetManufacturerLists/${mfgId}/${productTypeId}`;
          if (lastMfgUrl !== url) {
            return api.fetch(url).then((r) => {
              setLastMfgUrl(url);
              const changedUnitTypes = ui_helpers.idNameToValueLabel(r.data.unitTypeList, null, false);
              spOptions = ui_helpers.idNameToValueLabel(r.data.subproductTypeList);
              const changedBaseColors = ui_helpers.idNameToValueLabel(r.data.baseColorList);
              const changedTrimColors = ui_helpers.idNameToValueLabel(r.data.trimColorList);
              const changedRoofColors = ui_helpers.idNameToValueLabel(r.data.roofColorList);
              // if there is a subproduct allow the more specific call to load these lists
              if (!subproductId || subproductId === constants.OTHER_ID) {
                setBaseColors(changedBaseColors);
                setTrimColors(changedTrimColors);
                setRoofColors(changedRoofColors);
              }
              setSubproductTypes(spOptions);
              if (changedUnitTypes) setUnitTypes(changedUnitTypes);
              if (r.data.defaultUnitTypeId) setDefaultUnitTypeId(r.data.defaultUnitTypeId)
              setHideUnitType(r.data.hideUnitType)
              // we only set this based on the manufacturing setting here
              // if it has not already been determined by an existing, saved relationship from a paired inventory
              if (!newInventoryId) {
                setEnableBuildFeature(r.data.enableBuildFeature);
              }
              setEnableCustomSerialGeneration(r.data.enableCustomSerialGeneration);
              ui_helpers.resolveListEntryPropsByText(newContractData, 'subproduct', 'otherSubproduct', spOptions, 'unitManufacturerSubproductText');
              ui_helpers.resolveListEntryPropsByText(newContractData, 'unitType', 'otherUnitType', changedUnitTypes);
              ui_helpers.resolveListEntryPropsByText(newContractData, 'roofColor', 'otherRoofColor', changedRoofColors, 'unitManufacturerRoofColorText');
              ui_helpers.resolveListEntryPropsByText(newContractData, 'trimColor', 'otherTrimColor', changedTrimColors, 'unitManufacturerTrimColorText');
              ui_helpers.resolveListEntryPropsByText(newContractData, 'baseColor', 'otherBaseColor', changedBaseColors, 'unitManufacturerBaseColorText');
              // newContractData.subproductSku = skuId
              //   ? { label: newContractData.unitManufacturerSubProductSkuName, value: skuId}
              //   : newContractData.subproductSku
              //     ? newContractData.subproductSku
              //     : constants.OTHER_OPTION;
              if (skuId === constants.OTHER_ID) {
                newContractData.otherWidth = newContractData.otherWidth === "" ? newContractData.widthFeet : newContractData.otherWidth;
                newContractData.otherLength = newContractData.otherLength === "" ? newContractData.lengthFeet : newContractData.otherLength;
              }
              const resolvedUnitTypes = changedUnitTypes ? changedUnitTypes : unitTypes;
              if (r.data.hideUnitType 
                && r.data.defaultUnitTypeId
                && (!newContractData.unitType || newContractData.unitType.value !== r.data.defaultUnitTypeId)
                && _.some(resolvedUnitTypes, ut => ut.value === r.data.defaultUnitTypeId)) {
                newContractData.unitType = _.find(resolvedUnitTypes, ut => ut.value === r.data.defaultUnitTypeId);
              }
              // applyPhaseList();
              return newContractData;
            }).catch(api.catchHandler);
          } else {
            return newContractData;
          }
        }
      }
    })
// getSubProductSkuOptions();
    .then(newContractData => {
      if (subproductId === newContractData.subproduct?.value) {
        return newContractData;
      } else {
        // console.log("SP", subproductId, newContractData.subproduct?.value)
        if (!subproductId || subproductId === constants.OTHER_ID) {
          skuOptions = [constants.OTHER_OPTION];
          setSubProductSkuOptions(skuOptions);
          newContractData.subproductSku = constants.OTHER_OPTION;
          return newContractData;
        } else {
          let url = "contract/GetMfgSubProductSkuOptions/" + subproductId;
          if (lastSkuUrl === url) {
            return newContractData;
          } else {
            setLastSkuUrl(url)
            return api.fetch(url).then((r) => {
              skuOptions = _.chain(r.data)
                .sortBy(['width', 'length'])
                .map(d => ({ ...d, value: d.id, label: d.displayName }))
                .value();
              skuOptions.unshift(constants.OTHER_OPTION);
              setSubProductSkuOptions(skuOptions);
              // const width = newContractData.otherWidth === "" ? newContractData.widthFeet : newContractData.otherWidth
              // const length = newContractData.otherLength === "" ? newContractData.lengthFeet : newContractData.otherLength;
              // const oldSku = newContractData.unitManufacturerSubProductSkuId
              //   ? { label: newContractData.unitManufacturerSubProductSkuName, value: newContractData.unitManufacturerSubProductSkuId}
              //   : newContractData.subproductSku;
              // const selectedItem = oldSku
              //   ? _.find(skuOptions, t => t.value === oldSku.value)
              //   : _.find(skuOptions, t => t.width === width && t.length === length);
              // if (selectedItem) {
              //   newContractData.subproductSku = selectedItem;
              // } else {
              //   newContractData.subproductSku = constants.OTHER_OPTION;
              //   newContractData.otherWidth = width;
              //   newContractData.widthFeet = constants.OTHER_ID;
              //   newContractData.otherLength = length;
              //   newContractData.lengthFeet = constants.OTHER_ID;
              // }
              return newContractData;
            }).catch((err) => console.error("There was an issue fetching size options: " + err));
          }
        }
      }
    })
// getSubProductColors();
    .then(newContractData => {
      if (subproductId && subproductId > 0 && productTypeId > 0 && lastSubproductId !== subproductId) {
        const url = `contract/GetManufacturerLists/${mfgId}/${productTypeId}/${subproductId}`;
        setLastSubproductId(subproductId);
        return api.fetch(url).then((r) => {
          bcOptions = ui_helpers.idNameToValueLabel(r.data.baseColorList);
          trimOptions = ui_helpers.idNameToValueLabel(r.data.trimColorList);
          roofOptions = ui_helpers.idNameToValueLabel(r.data.roofColorList);
          setBaseColors(bcOptions);
          setTrimColors(trimOptions);
          setRoofColors(roofOptions);
          return newContractData;
        }).catch((e) => console.error(e));
      } else {
        return newContractData;
      }
    })
// getMfgSubproductSkuAddOnOptions();
    .then(newContractData => {
      if (skuId === newContractData.subproductSku?.value) {
        return newContractData;
      } else {
        // console.log('SKU', skuId, newContractData.subproductSku?.value)
        let url = '';
        if (!mfgId || mfgId === constants.OTHER_ID || !skuId)
        {
          setAvailableUnitOptions([
            { value: constants.SELECT_OPTION_ID, label: "Select Option" },
            constants.OTHER_OPTION
          ]);
          setLastSkuAddonUrl("");
          return newContractData;
        } else if (skuId && skuId === constants.OTHER_ID && mfgId !== constants.OTHER_ID) {
          url = 'contract/ListManufacturerAddons';
          const urlId = `${url}/${mfgId}`;
          if (lastSkuAddonUrl === urlId) {
            return newContractData;
          } else {
            setLastSkuAddonUrl(urlId);
            return api.post(url, { activeOnly: true, id: mfgId }).then(r => {
              if (!r.data.success) return;
              let list = r.data.message;
              if (!_.some(list, l => l.name === constants.OTHER)) {
                list.unshift(getOtherAddOnOption());
              }
              setAvailableUnitOptions(transformAddOns(list, skuId));
              return newContractData;
            }).catch(api.catchHandler);
          }
        } else if (skuId !== constants.OTHER_ID) {
          url = `inventory/GetMfgProductSkuAddOnAndDefaultOptions/${mfgId}/${skuId}`;
          if (url === lastSkuAddonUrl) {
            return newContractData;
          } else {
            setLoadingSkuAddonOptions(true);
            return api.fetch(url).then(r  => {
              if (!r.data) return;
              setLastSkuAddonUrl(url);
              setAvailableUnitOptions(transformAddOns(r.data.mfgUnitOptions, skuId));
              return newContractData;
            }).catch(err => console.error(err))
            .finally(()=> setLoadingSkuAddonOptions(false));
          }
        }
      }
    })
    .then(newContractData => {
      let newData = null;
      setContractData(oldData => {
        newContractData.subproductSku = _.find(skuOptions, m => m.value === skuId);
        if (newContractData.subproductSku) {
          newContractData.widthFeet = newContractData.subproductSku.width;
          newContractData.lengthFeet = newContractData.subproductSku.length;
        } else {
          newContractData.subproductSku = constants.OTHER_OPTION;
          newContractData.widthFeet = "0";
          newContractData.lengthFeet = "0";
        }
        newData = {
          ...oldData, 
          ...newContractData,
          manufacturer: _.find(manufacturers, m => m.value === mfgId),
          productType: _.find(ptOptions, m => m.value === productTypeId),
          subproduct: _.find(spOptions, m => m.value === subproductId),
          contractAddOns: oldData.contractAddOns,
          unitPrice: newUnitPrice, 
          inventoryId: newInventoryId
        };
        return newData;
      });
      return {newUnitData: newData, spOptions, skuOptions, bcOptions, trimOptions, roofOptions};
    }).catch(err => console.error('Error in pairing promise chain', err));
  }

  function getSubProductColors() {
    if (window.isPairing
      || !contractData.subproduct
      || (contractData.subproduct && contractData.subproduct.value < 0)
      || !contractData.productType
      || (contractData.productType && contractData.productType.value < 0)
      || lastSubproductId === contractData.subproduct.value) return;
    const mfgId = resolveManufacturerId();
    // reusing this same api to fetch colors for a specific subproduct; it's cached server-side
    const url = `contract/GetManufacturerLists/${mfgId}/${contractData.productType.value}/${contractData.subproduct.value}`;
    setLastSubproductId(contractData.subproduct.value);
    api.fetch(url).then((r) => {
      setBaseColors(ui_helpers.idNameToValueLabel(r.data.baseColorList));
      setTrimColors(ui_helpers.idNameToValueLabel(r.data.trimColorList));
      setRoofColors(ui_helpers.idNameToValueLabel(r.data.roofColorList));
    }).catch((e) => console.error(e));
  }

  function onDeleteSalesperson() {
    if (!contractData.salesperson) return;
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you wish to delete ${contractData.salesperson.label}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api
              .post("contract/DeleteSalesperson", {
                id: contractData.salesperson.value,
              })
              .then((r) => {
                setContractData(oldData => ({
                  ...oldData, 
                  salesperson: null
                }));
                setSalespeople(
                  _.reject(
                    salespeople,
                    (p) => p.value === contractData.salesperson.value
                  )
                );
              })
              .catch((e) => console.error(e));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  const continueWithDownPayment = () => {
    setSkipValidation(true);
    setDownPaymentOverPayment(false);
    setContractData(oldData => ({
      ...oldData, 
      includedInitialPayment: true
    }));
  };

  function setInitialAmountToMinimum() {
    const minPmt = contractData.selectedTermInfo.requiredForDeliveryAmount_NOPR + ui_helpers.sideFeeSum(priceAdjustments);
    setContractData(oldData => ({
      ...oldData, 
      initialPaymentAmount: minPmt
    }));
    setDownPaymentOverPayment(false);
  }

  function getUnpairedInventory() {
    if (searchingUnpaired || !searchText || searchText.length < 3 || window.isPairing) return;
    setSearchingUnpaired(true);
    const payload = {
      searchText: searchText,
      page: unpairedInvList ? unpairedInvList.pageNumber : 1,
      pageSize: 3
    };
    api.post('Inventory/GetMfgUnpairedInventoryList', payload).then(res => {
      if (res.data && res.data.success) {
        setUnpairedInvList(res.data.message)
      }
    }).catch(err => console.error(err))
    .finally(() => setSearchingUnpaired(false));
  }

  function checkTermPromoAvailability(code, term) {
    if (!contractData.selectedTermInfo) return;
    const payload = {
      promoCode: code,
      companyId: contractData.companyId,
      contractTermId: contractData.selectedTermInfo.contractTermId,
      productTypeId: contractData.productType 
        ? contractData.productType.value
        : null,
      activeOnly: true
    };
    const stringifiedTermCheck = JSON.stringify(payload);
    if (stringifiedTermCheck === lastPromoCodeTermCheck) return;
    setLastPromoCodeTermCheck(stringifiedTermCheck);
    api.post("Contract/PromoCodeTermCheck", payload).then(res => {
      if (res.data && !res.data.success){
        setFieldWarnings([{
          field: "promoCodeTermCheck",
          text: res.data.message
        }]);
      } else {
        setFieldWarnings(_.reject(fieldWarnings, w => w.field === "promoCodeTermCheck"))
      }
    }).catch(err => console.error(err))
  }

  function assessValidity(phaseName) {
    let warnings = [];
    const qt = isQuote();
    const rto = isRTO();
    switch (phaseName) {
      case PHASE_NAMES.CUSTOMER:
        if (
          !contractData.firstName ||
          _.trim(contractData.firstName).length < 2
        ) {
          warnings.push({ text: "Provide a first name.", field: "firstName" });
        }
        if (
          !contractData.lastName ||
          _.trim(contractData.lastName).length < 2
        ) {
          warnings.push({ text: "Provide a last name.", field: "lastName" });
        }
        if (rto &&
          (!contractData.licenseNo ||
            _.trim(contractData.licenseNo).length < 5) &&
          (!contractData.ssn || _.trim(contractData.ssn).length < 9)
        ) {
          warnings.push({
            text: "Provide either a SSN or a License #.",
            field: "ssn",
          });
        }
        if (rto &&
          contractData.ssn &&
          contractData.ssn.replace(/[-]/g, "").length !== 9
        ) {
          warnings.push({
            text: "Either leave Customer SSN empty or enter a valid value.",
            field: "ssn",
          });
        }
        if (!rto && !qt && !contractData.companyId && ownershipCompanyList && ownershipCompanyList.length > 1) {
          warnings.push({
            text: "Select a company that will own this cash sale.",
            field: "companyId"
          });
        }
        if (!qt && 
          (!contractData.customerPhone1 ||
            _.trim(contractData.customerPhone1).length < 10) &&
          (!contractData.secondaryPhone ||
            _.trim(contractData.secondaryPhone).length < 10)
        ) {
          warnings.push({
            text: "Provide either a Cell phone or a Secondary phone.",
            field: "customerPhone1",
          });
        }
        if (rto && !contractData.dateOfBirth) {
          warnings.push({
            text: "Provide a valid date of birth.",
            field: "dateOfBirth",
          });
        }
        if (rto &&
          contractData.dateOfBirth &&
          date_helpers.calculateAge(contractData.dateOfBirth) < 18
        ) {
          warnings.push({
            text: "Customers must be 18 years old or older.",
            field: "dateOfBirth",
          });
        }
        if (!contractData.region || (contractData.region && !contractData.region.value)) {
          warnings.push({ text: "Select a region.", field: "region" });
        }
        if (!qt) {
          if (!contractData.deliveryName || _.trim(contractData.deliveryName).length < 2) {
            warnings.push({
              text: "Provide a delivery name.",
              field: "deliveryName",
            });
          }
          if (!contractData.deliveryAddress1 || _.trim(contractData.deliveryAddress1).length < 2) {
            warnings.push({
              text: "Provide a delivery address.",
              field: "deliveryAddress1",
            });
          }
          if (!contractData.deliveryCity || _.trim(contractData.deliveryCity).length < 2) {
            warnings.push({
              text: "Provide a delivery city.",
              field: "deliveryCity",
            });
          }
          if (!contractData.deliveryState || _.trim(contractData.deliveryState).length < 2) {
            warnings.push({
              text: "Provide a delivery state.",
              field: "deliveryState",
            });
          }
          if (!contractData.deliveryZip || _.trim(contractData.deliveryZip).length < 2) {
            warnings.push({
              text: "Provide a delivery zip.",
              field: "deliveryZip",
            });
          }
          if (!contractData.billingName || _.trim(contractData.billingName).length < 2) {
            warnings.push({
              text: "Provide a billing name.",
              field: "billingName",
            });
          }
          if (!contractData.billingAddress1 || _.trim(contractData.billingAddress1).length < 2) {
            warnings.push({
              text: "Provide a billing address.",
              field: "billingAddress1",
            });
          }
          if (!contractData.billingCity || _.trim(contractData.billingCity).length < 2) {
            warnings.push({
              text: "Provide a billing city.",
              field: "billingCity",
            });
          }
          if (!contractData.billingState || _.trim(contractData.billingState).length < 2) {
            warnings.push({
              text: "Provide a billing state.",
              field: "billingState",
            });
          }
          if (!contractData.billingZip || _.trim(contractData.billingZip).length < 2) {
            warnings.push({
              text: "Provide a billing zip.",
              field: "billingZip",
            });
          }
        }
        if (!contractData.region) {
          warnings.push({
            text: "Region is required",
            field: "region"
          });
        }
        if (contractData.region &&
          contractData.deliveryState &&
          parseInt(contractData.region.value, 10) !== constants.OTHER_ID &&
          contractData.region.abbr.toUpperCase() !== contractData.deliveryState.toUpperCase()
        ) {
          warnings.push({
            text: "Region and delivery state must match.",
            field: "region"
          });
        }
        break;
      case PHASE_NAMES.RELATIONS:
        if (rto) {
          if (
            contractData.corenterEmail &&
            !input_validation.is_email(contractData.corenterEmail)
          ) {
            warnings.push({
              text: "Provide a valid corenter email address or leave it empty.",
              field: "corenterEmail",
            });
          }
          // if (!contractData.employerEmail || !input_validation.is_email(contractData.employerEmail)) {
          //   warnings.push({text: "Provide a valid corenter email address or leave it empty.", field: 'employerEmail'});
          // }
          if (!contractData.employmentType) {
            warnings.push({
              text: "Provide a valid employment type.",
              field: "employmentType",
            });
          }
          if (
            contractData.employmentType &&
            constants.EMPLOYMENT_TYPES_REQUIRING_EMPLOYER.includes(
              contractData.employmentType.label
            )
          ) {
            if (
              !contractData.employerName ||
              _.trim(contractData.employerName).length < 2
            ) {
              warnings.push({
                text: "Missing employer name.",
                field: "employerName",
              });
            }
          }
          if (!contractData.propertyOwner) {
            if (
              !contractData.landOwnerFirstName ||
              _.trim(contractData.landOwnerFirstName).length < 2
            ) {
              warnings.push({
                text: "Landowner First Name is required (since the customer is not the landowner).",
                field: "landOwnerFirstName",
              });
            }
            if (
              !contractData.landOwnerLastName ||
              _.trim(contractData.landOwnerLastName).length < 2
            ) {
              warnings.push({
                text: "Landowner Last Name is required (since the customer is not the landowner).",
                field: "landOwnerLastName",
              });
            }
            if (
              !contractData.landOwnerAddress1 ||
              _.trim(contractData.landOwnerAddress1).length < 2
            ) {
              warnings.push({
                text: "Landowner Address is required (since the customer is not the landowner).",
                field: "landOwnerAddress1",
              });
            }
            if (
              !contractData.landOwnerCity ||
              _.trim(contractData.landOwnerCity).length < 2
            ) {
              warnings.push({
                text: "Landowner City is required (since the customer is not the landowner).",
                field: "landOwnerCity",
              });
            }
            if (
              !contractData.landOwnerState ||
              _.trim(contractData.landOwnerState).length < 2
            ) {
              warnings.push({
                text: "Landowner State is required (since the customer is not the landowner).",
                field: "landOwnerState",
              });
            }
            if (
              !contractData.landOwnerZip ||
              _.trim(contractData.landOwnerZip).length < 2
            ) {
              warnings.push({
                text: "Landowner Zip is required (since the customer is not the landowner).",
                field: "landOwnerZip",
              });
            }
            if (
              !contractData.landOwnerPhone ||
              _.trim(contractData.landOwnerPhone).length < 2
            ) {
              warnings.push({
                text: "Landowner Phone is required (since the customer is not the landowner).",
                field: "landOwnerPhone",
              });
            }
          }
          const corenterFieldsProvided =
            contractData.corenterFirstName ||
            contractData.corenterLastName ||
            contractData.corenterAddress1 ||
            contractData.corenterAddress2 ||
            contractData.corenterCity ||
            contractData.corenterState ||
            contractData.corenterZip ||
            contractData.corenterPhone ||
            contractData.corenterEmail;
          if (
            corenterFieldsProvided &&
            (!contractData.corenterFirstName ||
              !contractData.corenterLastName ||
              !contractData.corenterAddress1 ||
              !contractData.corenterCity ||
              !contractData.corenterState ||
              !contractData.corenterZip ||
              !contractData.corenterPhone ||
              !contractData.corenterDateOfBirth)
          ) {
            warnings.push({
              text: "Corenter information is optional, but all fields are required if any are entered. Either clear or enter all corenter data.",
              field: "corenterFirstName",
            });
          }
          if (
            !contractData.contact1FirstName ||
            _.trim(contractData.contact1FirstName).length < 2
          ) {
            warnings.push({
              text: "At least 1 valid, additional contact is required.",
              field: "contact1FirstName",
            });
          }
          if (
            !contractData.contact1LastName ||
            _.trim(contractData.contact1LastName).length < 2
          ) {
            warnings.push({
              text: "At least 1 valid, additional contact is required.",
              field: "contact1LastName",
            });
          }
          if (
            !contractData.contact1Phone ||
            _.trim(contractData.contact1Phone).length < 2
          ) {
            warnings.push({
              text: "At least 1 valid, additional contact is required.",
              field: "contact1Phone",
            });
          }
          if (
            !contractData.contact1Relationship ||
            _.trim(contractData.contact1Relationship).length < 2
          ) {
            warnings.push({
              text: "At least 1 valid, additional contact is required.",
              field: "contact1Relationship",
            });
          }
          if (
            contractData.contact1Email &&
            !input_validation.is_email(contractData.contact1Email)
          ) {
            warnings.push({
              text: "Either enter a valid additional contact email address (1) or leave it empty.",
              field: "contact1Email",
            });
          }
        }
        break;
      case PHASE_NAMES.UNITS:
        if (!enableCustomSerialGeneration && (
          !contractData.serialNumber ||
          _.trim(contractData.serialNumber).length < 2
        )) {
          warnings.push({
            text: "Either enter a serial # (TBD or MADE TO ORDER is acceptable if appropriate).",
            field: "serialNumber",
          });
        }
        const mfgId = resolveManufacturerId();
        if (mfgId === constants.OTHER_ID && (
          !contractData.otherManufacturer || _.trim(contractData.otherManufacturer).length < 2
        )) {
          warnings.push({
            text: "Either select a valid manufacturer or enter a description.",
            field: "manufacturer",
          });
        }
        if (!contractData.subproductSku
            || (contractData.subproductSku && contractData.subproductSku.value === constants.OTHER_ID)) 
        {
          if (!contractData.otherWidth) {
            warnings.push({
              text: "Either select a valid size or enter a width description.",
              field: "widthFeet",
            });
          }
          if (!contractData.otherLength) {
            warnings.push({
              text: "Either select a valid size or enter a length description.",
              field: "lengthFeet",
            });
          }
        }
        if(!qt && !contractData.buildingOrientation) {
          warnings.push({
            text: 'You must choose a building orientation.',
            field: 'buildingOrientation',
          });
        }
        if (!contractData.unitType && !contractData.hideUnitType) {
          warnings.push({ text: "Material is missing.", field: "unitType" });
        }
        if (contractData.unitType
          && contractData.unitType.value === constants.OTHER_ID 
          && (!contractData.otherUnitType || _.trim(contractData.otherUnitType).length < 2)
          && !contractData.hideUnitType
        ) {
          warnings.push({
            text: "Either select a valid material or enter a description.",
            field: "unitType",
          });
        }
        if (
          contractData.productType &&
          !contractData.baseColor && contractData.otherBaseColor === "" &&
          contractData.productType.value !== constants.PRODUCT_TYPE_IDS.CARPORT
        ) {
          warnings.push({ text: "Base color is missing.", field: "baseColor" });
        }
        if (
          contractData.productType &&
          contractData.baseColor &&
          contractData.baseColor.value === constants.OTHER_ID &&
          contractData.productType.value !==
            constants.PRODUCT_TYPE_IDS.CARPORT &&
          (!contractData.otherBaseColor ||
            _.trim(contractData.otherBaseColor).length < 2)
        ) {
          warnings.push({
            text: "Either select a valid base color or enter a description.",
            field: "baseColor",
          });
        }
        if (!contractData.trimColor && contractData.otherTrimColor === "") {
          warnings.push({ text: "Trim color is missing.", field: "trimColor" });
        }
        if (
          contractData.trimColor &&
          contractData.trimColor.value === constants.OTHER_ID &&
          (!contractData.otherTrimColor ||
            _.trim(contractData.otherTrimColor).length < 2)
        ) {
          warnings.push({
            text: "Either select a valid trim color or enter a description.",
            field: "trimColor",
          });
        }
        if (!contractData.roofColor && contractData.otherRoofColor === "") {
          warnings.push({ text: "Roof color is missing.", field: "roofColor" });
        }
        if (
          contractData.roofColor &&
          contractData.roofColor.value === constants.OTHER_ID &&
          (!contractData.otherRoofColor ||
            _.trim(contractData.otherRoofColor).length < 2)
        ) {
          warnings.push({
            text: "Either select a valid roof color or enter a description.",
            field: "roofColor",
          });
        }
        if (!contractData.subproduct && contractData.otherSubproduct === "") {
          warnings.push({ text: "Style is missing.", field: "subproduct" });
        }
        if (
          contractData.subproduct &&
          contractData.subproduct.value === constants.OTHER_ID &&
          (!contractData.otherSubproduct ||
            _.trim(contractData.otherSubproduct).length < 2)
        ) {
          warnings.push({
            text: "Either select a valid style or enter a description.",
            field: "subproduct",
          });
        }
        if(!qt && !contractData.buildingOrientation) {
          warnings.push({
            text: 'Select a Delivery Building Orientation.',
            field: 'deliveryBuildingOrientation'
          })
        }
        if(contractData.unitIsNew === null || contractData.unitIsInventory === null){
            warnings.push({
                text: 'Both Unit Condition and Inventory type are required.',
                field: 'unitIsNew'
            });
        }
        break;
      case PHASE_NAMES.PRICING:
        if (rto) {
          if(contractData.deliveryCounty === "" || !contractData.deliveryCounty){
              warnings.push({
                  text: "Delivery county is required.",
                  field: "deliveryCounty",
              });
          }
          if(contractData.deliveryCounty === "" && (contractData.taxRate === "" || !contractData.taxRate) && parseInt(contractData.taxRate, 10) !== 0) {
              warnings.push({
                  text: "Please enter a valid Delivery county and/or Tax Rate. You can use the 'Find Tax Rate' feature to look up the appropriate county and rate. Be sure to check whether or not the county is within city limits.",
                  field: "deliveryCounty",
              });
          }
          if (!contractData.selectedTermInfo) {
            warnings.push({
              text: "You must select a contract term.",
              field: "initialPaymentAmount",
            });
          }
          if (
            !contractData.unitPrice ||
            parseFloat(contractData.unitPrice) < 10.0
          ) {
            warnings.push({
              text: "Unit price must be a valid value.",
              field: "unitPrice",
            });
          }
          if (!contractData.salesperson) {
            warnings.push({
              text: "You must select a salesperson.",
              field: "salesperson",
            });
          }
          if (
            contractData.salesperson &&
            contractData.salesperson.value === constants.OTHER_ID &&
            (!contractData.otherSalesperson ||
              _.trim(contractData.otherSalesperson).length < 2)
          ) {
            warnings.push({
              text: "Either select a valid salesperson or enter a description.",
              field: "otherSalesperson",
            });
          }
          if (
            contractData.estimatedDeliveryDate &&
            date_helpers.isBefore(contractData.estimatedDeliveryDate, moment())
          ) {
            warnings.push({
              text: `The estimated delivery date must either be left blank or be set to a date in the future. ${date_helpers.formatDateToShortDate(
                contractData.estimatedDeliveryDate
              )} is in the past.`,
              field: "estimatedDeliveryDate",
            });
          }
          if (
            contractData.selectedTermInfo &&
            contractData.initialPaymentAmount < contractData.selectedTermInfo.requiredForDeliveryAmount_NOPR
          ) {
              warnings.push({
                text: `The initial payment amount (${contractData.initialPaymentAmount}) is below the minimum required for delivery amount `,
                field: "initialPaymentAmount",
              });
          }
          // if( contractData.selectedTermInfo && contractData.initialPaymentAmount
          //     > contractData.selectedTermInfo.requiredForDeliveryAmount){
          //   if(!contractData.includedInitialPayment && !skipValidation) setDownPaymentOverPayment(true);
          // }
          if (contractData.initialPaymentAmount > contractData.maxInitialPaymentAmount) {
            warnings.push({text: `You've exceeded the maximum amount paid today amount of {0:N}% of the unit price ({1:C}).`, field: 'initialPaymentAmount'});
          }
          if(contractData.initialPaymentAmount === "0.00"){
              warnings.push({text: "You must enter an a Down Payment.", field: "initialPaymentAmount"});
          }
          if(contractData.isTaxExempt && !contractData.taxExemptionForm) {
              warnings.push({text: "If this item is marked as tax exempt you must provide an exemption form.", field: "taxRate"});
          }
          // if(contractData.isTaxExempt &&
          //     (contractData.taxExemptFormToUpload === null && contractData.taxExemptionForm === null)) {
          //
          // }
          if(contractData.taxRate === 0 && !contractData.isTaxExempt) {
            warnings.push({text: "Tax rate was entered as 0. Either mark this item as Tax Exempt or enter a valid tax rate.", field: "taxRate"});
          }
        }
        break;
      case PHASE_NAMES.QUOTE_PRICING:
        if (contractData.convertTo === constants.RTO_STRING) {
          if (!contractData.selectedTermInfo) {
            warnings.push({
              text: "You must select a contract term.",
              field: "selectedContractTerm",
            });
          }
          if (
            !contractData.unitPrice ||
            parseFloat(contractData.unitPrice) < 10.0
          ) {
            warnings.push({
              text: "Unit price must be a valid value.",
              field: "unitPrice",
            });
          }
          if (
            contractData.mfgExpirationDate &&
              date_helpers.isBefore(contractData.mfgExpirationDate, moment())
          ) {
            warnings.push({
              text: `The expiry date must be set to a date in the future. ${date_helpers.formatDateToShortDate(
                  contractData.mfgExpirationDate
              )} is in the past.`,
                field: "mfgExpirationDate",
            });
          }
        }
        break;
      case PHASE_NAMES.BUILD:
        if (enableBuildFeature && !contractData.inventoryId) {
          warnings.push({ text: "You must complete the Unit Information before continuing with this step.", field: "buildStep" });
        }
        break;
      case PHASE_NAMES.PAYMENT:
        const sumOfPayments = _.sumBy(paymentList, x => x.amount);
        if (rto) {
          if (!parseFloat(contractData.initialPaymentAmount) > 0.00) {
            warnings.push({text: 'You must provide an Initial Payment amount', field: 'initialPaymentAmount'});
          }
          if (contractData.initialPaymentAmount > 0 
            && paymentList.length > 0 
            && Math.round(parseFloat(contractData.initialPaymentAmount)) > Math.round(parseFloat(sumOfPayments))) {
            warnings.push({text: `The initial payment amount (${contractData.initialPaymentAmount}) is greater from the sum of entered payments (${sumOfPayments}). Either adjust the payment sum or correct the Initial Payment Amount.`, field: 'initialPaymentAmount'});
          }
        } else {
          if (contractData.deliveryCounty === "" || !contractData.deliveryCounty) {
              warnings.push({
                  text: "Delivery county is required.",
                  field: "deliveryCounty",
              });
          }
        }
        if (contractData.deliveryCounty === "" && (contractData.taxRate === "" || !contractData.taxRate) && parseInt(contractData.taxRate, 10) !== 0) {
          warnings.push({
              text: "Please enter a valid Delivery county and/or Tax Rate. You can use the 'Find Tax Rate' feature to look up the appropriate county and rate. Be sure to check whether or not the county is within city limits.",
              field: "deliveryCounty",
          });
        }
        if(contractData.isTaxExempt && !contractData.taxExemptionForm) {
          warnings.push({text: "If this item is marked as tax exempt you must provide an exemption form.", field: "taxRate"});
        }
        if(contractData.taxRate === 0 && !contractData.isTaxExempt) {
          warnings.push({text: "Tax rate was entered as 0. Either mark this item as Tax Exempt or enter a valid tax rate.", field: "taxRate"});
        }
        break;
    }
    return warnings;
  }

function saveContract(onSilentSaveCallback = null) {
  if (saving) return;
  const warnings = assessValidity(phaseList[phase]);
  setFieldWarnings(warnings);
  if (warnings.length) {
    setValidationMessage({
      message: "Please address the highlighted validation errors. Hover over a field to see error details.",
      flavor: "warning"
    });
    return;
  }
  if (loadingPage || loadingContract) return;
  setSaving(true);
  let payload = null;
  let url = "";
  const qt = isQuote();
  const rto = isRTO();
  switch (phaseList[phase]) {
  case PHASE_NAMES.CUSTOMER:
    payload = Object.assign({}, contractData);
    payload.typeOfContractStatus = qt
      ? constants.CONTRACT_STATUS_ID.Quote
      : constants.CONTRACT_STATUS_ID.Draft;
    payload.convertFromQuote = convertFromQuote ? true : false;
    payload.contractId = contractId;
    payload.dealerId = payload.dealer.value;
    payload.regionId = parseInt(payload.region.value, 10);
    payload.cultureId = payload.culture.value;
    payload.customerFirstName = payload.firstName;
    payload.customerLastName = payload.lastName;
    payload.customerPhone1 = payload.customerPhone1;
    payload.customerPhone2 = payload.secondaryPhone;
    payload.customerEmail = payload.email;
    payload.customerSSN = payload.ssn;
    payload.customerLicenseNumber = payload.licenseNo;
    payload.customerDateOfBirth = payload.dateOfBirth;
    if (payload.unitIsNew === null) {
      payload.unitIsNew = pristineUnitIsNew;
    }
    if (!rto && !qt && !payload.companyId && ownershipCompanyList && ownershipCompanyList.length === 1) {
      payload.companyId = ownershipCompanyList[0].id;
    }
    if (rto) {
      url = "contract/SaveCustomerA";
    // } else if (isQuote()) {
    //   url = "contract/SaveCustomerA";
    } else { // quotes go here too
      url = "cashSale/SaveCustomerA";
    }
    break;
  case PHASE_NAMES.RELATIONS:
    payload = Object.assign({}, contractData);
    payload.contractId = contractId;
    payload.customerEmploymentTypeId = payload.employmentType.value;
    payload.customerEmployerName = payload.employerName;
    payload.customerEmployerPhone = payload.employerPhone;
    payload.isPropertyOwner = payload.propertyOwner;
    payload.landownerFirstName = payload.landOwnerFirstName;
    payload.landownerLastName = payload.landOwnerLastName;
    payload.landownerAddress1 = payload.landOwnerAddress1;
    payload.landownerAddress2 = payload.landOwnerAddress2;
    payload.landownerCity = payload.landOwnerCity;
    payload.landownerState = payload.landOwnerState;
    payload.landownerPhone = payload.landOwnerPhone;
    payload.landownerZip = payload.landOwnerZip;
    payload.landownerEmail = payload.landOwnerEmail;
    if (payload.unitIsNew === null) {
      payload.unitIsNew = pristineUnitIsNew;
    }
    payload = { contract: payload, additionalContactList: [] };
    for (let i = 1; i <= 5; i++) {
      if (payload.contract[`contact${i}FirstName`]) {
        payload.additionalContactList.push({
          id: 0,
          seq: i,
          firstName: payload.contract[`contact${i}FirstName`],
          lastName: payload.contract[`contact${i}LastName`],
          phone: payload.contract[`contact${i}Phone`],
          email: payload.contract[`contact${i}Email`],
          relationshipToCustomer:
            payload.contract[`contact${i}Relationship`],
        });
      } else {
        break;
      }
    }
    delete payload.contract.contractAddOns;
    url = "contract/SaveCustomerB";
    break;
  case PHASE_NAMES.UNITS:
    payload = Object.assign({}, contractData);
    payload.serialNumber = !payload.serialNumber || (enableCustomSerialGeneration && payload.serialNumber === "")
      ? "TBD"
      : _.trim(payload.serialNumber);
    payload.contractId = contractId;
    payload.convertFromQuote = convertFromQuote ? true : false;
    payload.manufacturerId = resolveManufacturerId();
    payload.manufacturerOtherText = payload.otherManufacturer;
    delete payload.manufacturerName;
    payload.productTypeId = payload.productType.value;
    payload.MfgHideUnitTypeOption = hideUnitType;
    if (hideUnitType) {
      payload.unitTypeId = defaultUnitTypeId;
    } else {
      payload.unitTypeId = payload.unitType
        ? payload.unitType.value
        : constants.OTHER_ID;
    }
    payload.unitTypeOtherText = payload.otherUnitType;
    payload.unitManufacturerBaseColorText = payload.baseColor && payload.baseColor.label !== constants.OTHER
      ? payload.baseColor.label
      : payload.otherBaseColor;
    payload.unitManufacturerTrimColorText = payload.trimColor && payload.trimColor.label !== constants.OTHER
      ? payload.trimColor.label
      : payload.otherTrimColor;
    payload.unitManufacturerRoofColorText = payload.roofColor && payload.roofColor.label !== constants.OTHER
      ? payload.roofColor.label
      : payload.otherRoofColor;
    payload.skuId = payload.subproductSku
      ? payload.subproductSku.value
      : constants.OTHER_ID;
    payload.unitManufacturerSubproductId = payload.subproduct
      ? payload.subproduct.value
      : constants.OTHER_ID;
    payload.unitManufacturerSubproductText = payload.subproduct && payload.subproduct.label !== constants.OTHER
      ? payload.subproduct.label
      : payload.otherSubproduct;
    if (payload.skuId === constants.OTHER_ID || !payload.widthFeet) {
      payload.unitWidthFeet = null;
      payload.unitWidthFeetOtherText = payload.otherWidth;
    } else {
      payload.unitWidthFeet = payload.widthFeet;
      payload.unitWidthFeetOtherText = null;
    }
    if (payload.skuId === constants.OTHER_ID || !payload.lengthFeet) {
      payload.unitLengthFeet = null;
      payload.unitLengthFeetOtherText = payload.otherLength;
    } else {
      payload.unitLengthFeet = payload.lengthFeet;
      payload.unitLengthFeetOtherText = null;
    }
    if (payload.skuId === constants.OTHER_ID || payload.heightFeet === constants.OTHER_ID) {
      payload.unitHeightFeet = null;
      payload.unitHeightFeetOtherText = payload.otherHeight;
    } else {
      payload.unitHeightFeet = payload.unitHeightFeet;
      payload.unitHeightFeetOtherText = null;
    }
    payload.soldFromInventory = payload.unitIsInventory;
    payload.dealerId = payload.dealer.value;
    payload.companyId = 1; // this value is determined server-side
    payload.deliveryBuildingOrientation = payload.buildingOrientation && payload.buildingOrientation.label 
      ? payload.buildingOrientation.label
      : payload.buildingOrientation;
    payload.salespersonName = rto
      ? ""
      : payload.salesperson.label === constants.OTHER
        ? payload.otherSalesperson
        : payload.salesperson.label;
    payload.bundlesToDelete = payload.bundlesToDelete || [];
    delete payload.widthFeet;
    delete payload.lengthFeet;
    delete payload.heightFeet;
    if (qt) {
      const qdp = parseFloat(payload.quoteDownPayment);
      if (qdp >= 0.0) {
        payload.quoteDownPayment = qdp;
      } else {
        delete payload.quoteDownPayment;
      }
      // must smuggle this along for quotes since it is not saved to the model
      payload.inventoryId = fromInventoryId
        ? fromInventoryId
        : null;
      url = "CashSale/SaveQuote";
    } else if (rto) {
      delete payload.quoteDownPayment;
      url = "Contract/SaveUnit";
    } else {
      delete payload.quoteDownPayment;
      url = "CashSale/SaveUnit";
    }
    // console.log('about to save inventoryId of', payload.inventoryId, lastPairedInventoryId)
    if (lastPairedInventoryId) {
      payload.inventoryId = lastPairedInventoryId;
    }
    break;
  case PHASE_NAMES.BUILD:
    payload = Object.assign({}, contractData);
      payload.contractId = routeContractId;
      url = "contract/GenerateConditionalBuildSteps";
    break;
  case PHASE_NAMES.PRICING:
    payload = {
      contract: Object.assign({}, contractData),
      additionalContactList: [],
      promotionCode: contractData.promotionCodeTemp || '',
      priceOverrideCode: ""
    };
    payload.contract.salespersonName = payload.contract.salesperson && payload.contract.salesperson.label !== constants.OTHER
      ? payload.contract.salesperson.label
      : payload.contract.otherSalesperson;
    payload.contract.dueForDeliveryAmount = payload.contract.selectedTermInfo.requiredForDeliveryAmount_NOPR + ui_helpers.sideFeeSum(priceAdjustments);
    payload.contract.rentalCostAmount = payload.contract.selectedTermInfo.totalRentalAmount;
    payload.contract.purchaseReserveAmount = payload.contract.selectedTermInfo.purchaseReserveAmount;
    payload.contract.contractTermId = payload.contract.selectedTermInfo.contractTermId;
    payload.contract.dlwAmount = payload.contract.selectedTermInfo.dlwAmount;
    payload.contract.minimumSecurityDepositAmount = payload.contract.selectedTermInfo.minimumSecurityDepositAmount;
    payload.contract.contractId = contractId;
    payload.contract.monthlyPaymentAmount = payload.contract.selectedTermInfo.monthlyPaymentAmount;
    payload.contract.initialPaymentAmount = payload.contract.initialPaymentAmount;
    payload.contract.isTaxExempt = contractData.isTaxExempt;
    payload.contract.taxRate = contractData.isTaxExempt ? 0.0 : contractData.taxRate;
    payload.contract.deliveryCounty = contractData.deliveryCounty;
    payload.contract.isInCityLimits = contractData.isInCityLimits;
    if (payload.contract.contractAddOns) {
      delete payload.contract.contractAddOns;
    }
    url = "contract/SavePricing";
    break;
  case PHASE_NAMES.PAYMENT:
    if (rto) {
      url = "contract/SavePayment";
      payload = {
        contractId: contractId,
        skuId: contractData.subproductSku
          ? contractData.subproductSku.value
          : constants.OTHER_ID
      };
    } else {
      url = "cashSale/SaveCashSaleBalance";
      payload = {
        contractId: contractId,
        skuId: contractData.subproductSku
          ? contractData.subproductSku.value
          : constants.OTHER_ID,
        unitPrice: contractData.unitPrice,
        dueForDeliveryAmount: contractData.balanceDue,
        taxRate: contractData.isTaxExempt ? 0.0 : contractData.taxRate,
        deliveryCounty: contractData.deliveryCounty,
        isInCityLimits: contractData.isInCityLimits,
        isTaxExempt: contractData.isTaxExempt,
      };
    }
    break;
  }
  delete payload.manufacturer;
  delete payload.productType;
  delete payload.employmentType;
  delete payload.dealer;
  delete payload.region;
  delete payload.culture;
  delete payload.trimColors;
  delete payload.baseColors;
  delete payload.roofColors;
  delete payload.unitTypes;
  delete payload.subproductTypes;
  delete payload.manufacturerSubproductAddOns;
  if (payload.unitType) delete payload.unitType;
  if (payload.roofColor) delete payload.roofColor;
  if (payload.baseColor) delete payload.baseColor;
  if (payload.trimColor) delete payload.trimColor;
  if (payload.subproduct) delete payload.subproduct;
  if (payload.subproductSku) delete payload.subproductSku;
  if (payload.salesperson) delete payload.salesperson;
  if (payload.contractAddOns && payload.contractAddOns.length) {
    _.each(payload.contractAddOns, x => {
      if (!x.length) {
        x.length = 0.0;
      }
      if (!x.width) {
        x.width = 0.0;
      }
    });
  }
  api.post(url, payload).then((r) => {
    if (!r.data) return;
    if (onSilentSaveCallback && typeof onSilentSaveCallback === 'function') {
      onSilentSaveCallback();
      return;
    }
    if (r.data.success) {
      if (phase === 0) {
        if (r.data.success && r.data.message && r.data.message.taxSales) {
          setContractData(oldData => ({
            ...oldData, 
            taxRate: r.data.message.taxSales,
            taxRateString: toTaxRateString(r.data.message.taxSales),
            deliveryCounty: r.data.message.geoCounty
          }));
        } else {
          setContractData(oldData => ({
            ...oldData, 
            taxRate: 0.0,
            taxRateString: '0.000',
            deliveryCounty: ''
          }));
        }
      }
      else if (phaseList[phase] === PHASE_NAMES.UNITS && r.data.message)
      {
        if (wasInitialCreation && !contractData.inventoryId) {
          setAutoShowEditor(true);
        }
        setContractData(oldData => ({
          ...oldData, 
          taxRate: r.data.message.taxRate,
          taxRateString: toTaxRateString(r.data.message.taxRate),
          serialNumber: r.data.message.serialNumber,
          captiveInventoryId: r.data.message.captiveInventoryId
        }));
        setEnableBuildFeature(r.data.message.existingBuildFeature);
      }
      else if (phaseList[phase] === PHASE_NAMES.PRICING && !_.isNil(contractData.taxExemptionForm?.file)) {
        uploadTaxExemptionForm(contractData);
      }
      if (phase === phaseList.length - 1) {
        if(phaseList[phase] === PHASE_NAMES.PAYMENT && !_.isNil(contractData.taxExemptionForm?.file) && !isRTO()) {
          uploadTaxExemptionForm(contractData);
        }
        setRedirectTo(
            qt
                ? `${constants.PATH_NAMES.QUOTE_VIEW}/${contractId}/essentials`
                : rto
                    ? `${constants.PATH_NAMES.CONTRACT_VIEW}/${contractId}/essentials`
                    : `${constants.PATH_NAMES.CASH_SALE_VIEW}/${contractId}/essentials`
        );
      }
      else {
        setFieldWarnings([]);
        setValidationMessage(null);
        setPhase(phase + 1);
      }
    }
    else if (typeof r.data.message === 'object' && r.data.message !== null)
    {
        // {fieldName: {message, uifieldid, severity}} -> [{text: 'warning', field: 'fieldName'}]
        const serverWarnings = _.map(
          _.keys(r.data.message), (fieldName) => ({
            field: _.find(constants.UNITPRICE_SEVERITIY_FIELD_NAMES, f => {return f === fieldName}) !== undefined
              ? constants.severityUnitPrice
              : _.find(constants.INIT_PAYMENT_SEVERITIY_FIELD_NAMES, f => {return f === fieldName}) !== undefined
                ? constants.severityInitPayment
                : fieldName,
            text: r.data.message[fieldName].message,
          }));
        setFieldWarnings(serverWarnings);
        setValidationMessage({
          message: "Please address the highlighted validation errors. Hover over a field to see error details.", 
          flavor: "warning" 
        });
    } else {                        
      setValidationMessage({ message: r.data.message || "An unknown error occurred.", flavor: "warning" });
    }
  }).catch((err) => console.error(err))
    .finally(() => setSaving(false));
  }

  function toTaxRateString(r) {
    return (r * 100.0).toFixed(3);
  }

 function uploadTaxExemptionForm(contractData) {
    let tForm = {...contractData.taxExemptionForm};
    let formData = new FormData();
    formData.append("referenceId", contractId);
    formData.append("file", tForm.file);
    formData.append("typeOfAttachment", constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm);
    api.post_form_data('contract/UploadTaxExemptionForm', formData).then((res) => {
      if (!res || !res.data) return;
      if (res.data.success) {

      } else {
        Alert.error("There was an issue uploading the Tax exemption form.");
      }
    }).catch(api.catchHandler);
  }

  function findCustomer(probeSSN = "", probeLicenseNo = "") {
    if (_.trim(probeSSN) === "" && _.trim(probeLicenseNo) === "") {
        setValidationMessage({ message: "First enter a value to look up.", flavor: "warning" });
      setTimeout(() => setValidationMessage(null), 4000);
      return;
    }
    probeSSN = probeSSN.replace(/[-]/g, "");
    probeLicenseNo = probeLicenseNo.replace(/[-]/g, "");
    api
      .post("contract/FindCustomer", {
        ssn: probeSSN,
        licenseNo: probeLicenseNo,
      })
      .then((r) => {
        if (r.data.success) {
          setContractData(oldData => ({
            ...oldData,
            firstName: r.data.message.firstName,
            lastName: r.data.message.lastName,
            mobilePhone: r.data.message.phone1,
            secondaryPhone: r.data.message.phone2,
            email: r.data.message.email,
            licenseNo: r.data.message.licenseNumber,
            dateOfBirth: r.data.message.dateOfBirthString,
            employmentType: _.find(employmentTypes, (t) => t.value === r.data.message.employmentTypeId),
            employerName: r.data.message.employerName,
            employerPhone: r.data.message.employerPhone
          }));
        } else {
          setValidationMessage({message: r.data.message, flavor: "warning"});
          setTimeout(() => setValidationMessage(null), 4000);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function onExitClick() {
    if (isQuote()) {
      // save quote silently before redirecting
      saveContract(() =>
        history.push(`${constants.PATH_NAMES.QUOTE_VIEW}/${contractId}/essentials`));
    } else {
      history.push(isRTO()
        ? `${constants.PATH_NAMES.CONTRACT_VIEW}/${contractId}/essentials`
        : `${constants.PATH_NAMES.CASH_SALE_VIEW}/${contractId}/essentials`);    
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

const isQ = isQuote();
return (
    <div>
      <Header agreementNumber={agreementNumber} />
      <Modal isOpen={downPaymentOverPayment} centered>
        <ModalHeader>
          Possible Overpayment
        </ModalHeader>
        <ModalBody>
          The "Down Payment Paid Today" is more than than the minimum required for delivery.
        </ModalBody>
        <ModalFooter>
          <Row>
            <ButtonGroup>
              <Button className={'bg-cf-success border-success'} onClick={continueWithDownPayment}>
                Continue with {contractData.initialPaymentAmount} down
              </Button>
              <Button onClick={setInitialAmountToMinimum}>
                Reduce to the minimum
              </Button>
            </ButtonGroup>
          </Row>
        </ModalFooter>
      </Modal>
      <div className="p-2">
        <Container fluid>
          <Row className="mt-1" style={{ borderBottom: "solid 1px #999" }}>
            <Col xs={2}>
              <div className="header-text text-nowrap">{phaseList[phase]}</div>
            </Col>
            <Col xs={1} className={'d-flex align-items-center justify-content-start'}>
              {loadingPage || loadingContract ? <Spinner size='sm' /> : null}
            </Col>
            <Col xs={8}>
              <ContractCrumbs currentPhase={phase} onSetPhase={setPhase} />
            </Col>
            <Col xs={1}>
              <Button
                color="dark"
                size="sm"
                className="float-end"
                onClick={onExitClick}
                style={{ width: "120px", fontWeight: "bold" }}
              >
                {loadingPage || loadingContract
                  ? (<Spinner animation="border" size={"sm"} />)
                  : ("Exit")
                }
              </Button>
            </Col>
          </Row>
          {loadingPage || loadingContract ? <Loading /> : null}
          {validationMessage ? (
            <Row>
              <Col sm="12">
                <Alert2
                  color={validationMessage.flavor}//"warning"
                  className={classnames({ invalid: fieldWarnings.length })}
                >
                  {validationMessage.message} <FontAwesomeIcon icon="exclamation-circle" id="validationM"/>
{/*                  <UncontrolledTooltip target="validationM">{fieldWarnings.length && fieldWarnings.map(x => x.text).join(", ") }</UncontrolledTooltip> */}             
                  {fieldWarnings.length
                    ? (<div style={{fontWeight: 'normal'}}>{fieldWarnings.map(x => x.text).join(", ")}</div>)
                    : null
                  }   
                </Alert2>
              </Col>
            </Row>
          ) : null}
          {phaseList[phase] === PHASE_NAMES.CUSTOMER ? (
            <CustomerInformation
              key={phase}
              contractType={contractType}
              isQuote={isQ}
              contractData={contractData}
              dealers={dealers}
              regions={regions}
              cultures={cultures}
              fieldWarnings={fieldWarnings}
              onFindCustomer={findCustomer}
              ownershipCompanyList={ownershipCompanyList}
              onSetData={(d) => setContractData(oldData => ({...oldData, ...d}))}
              loading={loadingPage || loadingContract}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.RELATIONS ? (
            <CustomerInformation2
              contractType={contractType}
              contractData={contractData}
              employmentTypes={employmentTypes}
              fieldWarnings={fieldWarnings}
              onSetData={(d) => setContractData(oldData => ({...oldData, ...d}))}
              isQuote={isQ}
              loading={loadingPage || loadingContract}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.UNITS ? (
            <UnitInformation
              contractId={contractId}
              contractType={contractType}
              isQuote={isQ}
              unitPrice={contractData.unitPrice}
              getUnitPriceDisabled={getUnitPriceDisabled}
              contractData={contractData}
              serialNumber={contractData?.serialNumber}
              wasInitialCreation={wasInitialCreation}
              captiveInventoryId={contractData.captiveInventoryId}
              lastPairedInventoryId={lastPairedInventoryId}
              setLastPairedInventoryId={setLastPairedInventoryId}
              widths={widths}
              lengths={lengths}
              heights={heights}
              manufacturers={manufacturers}
              salespeople={salespeople}
              buildingOrientations={buildingOrientations}
              productTypes={productTypes}
              trimColors={trimColors}
              baseColors={baseColors}
              roofColors={roofColors}
              unitTypes={unitTypes}
              addOnOptions={availableUnitOptions}
              subproductTypes={subproductTypes}
              subProductSkuOptions={subProductSkuOptions}
              manufacturerSubproductAddOns={contractData.manufacturerSubproductAddOns}
              fieldWarnings={fieldWarnings}
              onSetValidationMessage={setValidationMessage}
              onSetDisabled={setDisableSale}
              onSetSalespeople={setSalespeople}
              onDeleteSalesperson={onDeleteSalesperson}
              onSetData={(d) => {
                let newData = null;
                setContractData(oldData => {
                  newData = {
                    ...oldData, 
                    ...d,
                    contractAddOns: oldData.contractAddOns
                  };
                  // console.log('unit info setdata contractdata unit price', newData.unitPrice, d.unitPrice)
                  return newData;
                });
              }}
              onSetDataAndAddOns={(d) => {
                let newData = null;
                setContractData(oldData => {
                  newData = {...oldData, ...d};
                  return newData;
                });
              }}
              customSerialEnabled={enableCustomSerialGeneration && contractData.unitIsNew === true && contractData.unitIsInventory === false}
              unpairedInvList={unpairedInvList}
              loadPairedInventoryLists={loadPairedInventoryLists}
              clearUnpairedInvList={() => {
                setUnpairedInvList(null); 
                setSearchText("");
              }}
              findUnpairedInv={getUnpairedInventory}
              setSearchText={setSearchText}
              searchText={searchText}
              onUpdateContractAddons={onUpdateContractAddons}
              setPairedAddons={setSavedAddons}
              setPageNumber={(e) => setUnpairedInvList({...unpairedInvList, pageNumber: e})}
              searchingUnpaired={searchingUnpaired}
              setIsPairing={(newValue) => {
                if (!newValue) {
                  setSearchingUnpaired(false);
                }
                window.isPairing = newValue;
              }}
              hideUnitType={hideUnitType}
              loading={loadingPage || loadingContract}
              enableBuildFeature={enableBuildFeature}
              isNewBuild={contractData.unitIsNew && !contractData.unitIsInventory}
              adjustmentTypeOptions={CASH_SALE_PRICE_ADJUSTMENT_OPTIONS}
              addPriceAdjustment={addPriceAdjustment}
              deletePriceAdjustment={deletePriceAdjustment}
              pendingPriceAdjustment={pendingPriceAdjustment}
              priceAdjustments={priceAdjustments}
              setPendingPriceAdjustment={setPendingPriceAdjustment}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.BUILD ? (
            <ContractBuild
              contractId={contractId}
              subProductId={contractData.unitManufacturerSubProduct && contractData.unitManufacturerSubProduct.value}
              subProductSkuId={contractData.unitManufacturerSubProductSkuId}
              subProductSkuName={contractData.unitManufacturerSubProductSkuName}
              serialNumber={contractData.serialNumber}
              setInventoryId={(e) => setContractData(oldData => ({...oldData, inventoryId: e}))}
              setContractSerial={(e) => setContractData(oldData => ({...oldData, serialNumber: e}))}
              inventoryId={contractData.inventoryId}
              contractData={contractData}
              refreshPrice={refreshPriceAndAddOns}
              onSaveContract={saveContract}
              autoShowEditor={autoShowEditor}
              setAutoShowEditor={setAutoShowEditor}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.PRICING ? (
            <ContractPricing
              unitPriceDisabled={unitPriceDisabled}
              adjustmentTypeOptions={RTO_PRICE_ADJUSTMENT_OPTIONS}
              addPriceAdjustment={addPriceAdjustment}
              deletePriceAdjustment={deletePriceAdjustment}
              pendingPriceAdjustment={pendingPriceAdjustment}
              priceAdjustments={priceAdjustments}
              setPendingPriceAdjustment={setPendingPriceAdjustment}
              contractId={contractId}
              contractType={contractType}
              contractData={contractData}
              productTypeId={contractData.productType ? contractData.productType.value : contractData.productTypeId}
              fieldWarnings={fieldWarnings}
              salespeople={salespeople}
              dueDays={dueDays}
              attchmentList={attachmentList}
              onSetDisabled={setDisableSale}
              setDownPaymentOverPayment={() => setDownPaymentOverPayment(true)}
              onSetSalespeople={(peeps) => setSalespeople(peeps)}
              onDeleteSalesperson={onDeleteSalesperson}
              onSetData={(d) => setContractData(oldData => ({...oldData, ...d}))}
              loading={loadingPage || loadingContract}
              width={contractData.otherWidth || contractData.widthFeet || contractData.unitWidthFeetOtherText}
              checkTermPromoAvailability={(code, term) => checkTermPromoAvailability(code, term)}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.PAYMENT ? (
            <Payment
              unitPriceDisabled={true} // 06-Jun-24, ART, Conrad says this should always be disabled on this screen 
              adjustmentTypeOptions={CASH_SALE_PRICE_ADJUSTMENT_OPTIONS}
              addPriceAdjustment={addPriceAdjustment}
              deletePriceAdjustment={deletePriceAdjustment}
              pendingPriceAdjustment={pendingPriceAdjustment}
              priceAdjustments={priceAdjustments}
              setPendingPriceAdjustment={setPendingPriceAdjustment}
              contractType={contractType}
              contractId={contractId}
              companyId={contractData?.companyId || originalCompanyId}
              paymentTypes={paymentTypes}
              contractData={contractData}
              fieldWarnings={fieldWarnings}
              cards={cards}
              paymentList={paymentList}
              setPaymentList={setPaymentList}
              onSetCards={setCards}
              onSetDisabled={setDisableSale}
              creditCardFeePercentage={contractData?.creditCardFeePercentage}
              onSetData={(d) => setContractData(oldData => ({...oldData, ...d}))}
              onSaveContract={saveContract}
              loading={loadingPage || loadingContract}
              attachmentList={attachmentList}
            />
          ) : null}
          {phaseList[phase] === PHASE_NAMES.QUOTE_PRICING ? (
            <QuotePricing
              contractType={contractType}
              contractId={contractId}
              contractData={contractData}
              fieldWarnings={fieldWarnings}
              onSetData={(d) => setContractData(oldData => ({...oldData, ...d}))}
              loading={loadingPage || loadingContract}
            />
          ) : null}
          <Row style={{ borderTop: "solid 1px #999" }}>
            <div className="d-flex justify-content-between pt-1 pb-5">
              <div>
{/*                <Button
                  size="lg"
                  color="danger"
                  className="text-light"
                  tag={Link}
                  to={
                      isRTO
                      ? PATHNAMES.RTOS
                      : PATHNAMES.CASH_SALES
                  }
                >
                  <FontAwesomeIcon icon="times-circle" className="me-2" />
                  Cancel
                </Button>
*/}                {phase > 0 ? (
                  <Button
                    size="lg"
                    color="dark"
                    className="ms-2"
                    onClick={() => setPhase((p) => p - 1)}
                  >
                    <FontAwesomeIcon icon="angle-left" className="me-2" />
                    Back
                  </Button>
                ) : null}
              </div>
              {phase === phaseList.length - 1 ? null : (
                <Button size="lg" color="dark" onClick={saveContract}>
                  {loadingPage || loadingContract || saving
                    ? (<Spinner animation="border" size={"sm"} />)
                    : ("Next")
                  }
                  <FontAwesomeIcon icon="angle-right" className="ms-2" />
                </Button>
              )}
              {phase === phaseList.length - 1 && !disableSale ? (<>
                <Button
                  color="primary"
                  className="float-end"
                  onClick={saveContract}
                  style={{ width: "220px", fontWeight: "bold" }}
                >
                  {loadingPage || loadingContract 
                    ? (<Spinner animation="border" size={"sm"} />) 
                    : (`Submit ${isQ ? "Quote" : ""}`)
                  }
                </Button>
              </>) : null}
            </div>
          </Row>
          <Row className="p-1">
              <Col>
                  {contractId
                    ? (<Button
                          onClick={() => setShowCommentModal(true)}
                          size="sm"
                          className="bg-success border-success float-end mb-2"
                        >
                          <FontAwesomeIcon icon="plus" className="me-1" />
                            Add Comment
                        </Button>)
                    : null
                  }
                  <Table size="sm">
                    <tbody>
                      {comments && comments.length
                        ? _.map(comments, c => 
                        (<tr key={c.id} className={classnames({private: c.isPrivate}, "comment-row")}>
                          <td className="content" dangerouslySetInnerHTML={{__html: c.dsc}} />
                          <td style={{width: "180px"}}>{c.createdAtLocalized}</td>
                          <td style={{width: "200px"}}>{c.userFullName}</td>
                          <td style={{ width: "50px" }}>
                              {(c.userId === currentUser.id || isSystemAdmin) && canManageComments &&
                                  (<Button color="danger" className="text-light" size="sm" title="Delete" onClick={() => deleteComment(c.id)}>
                              <FontAwesomeIcon icon='times-circle' />
                            </Button>)}
                          </td>
                        </tr>))
                        : (<tr><td className="muted-text">No Comments have yet been recorded</td></tr>)
                      }
                    </tbody>
                  </Table>
              </Col>
          </Row>
        </Container>
      </div>
      {showCommentModal
        ? (<Modal fullscreen="sm" toggle={() => setShowCommentModal(false)} isOpen>
            <ModalHeader>
              Add Comment
            </ModalHeader>
            <ModalBody>
              <Input type='textarea' className="comment-textarea" maxLength="800" value={comment} onChange={(e) => setComment(e.target.value)} />
              {ui_helpers.isContextCompanyUser(currentUser, contractData?.companyId) &&
                  <div className='mb-1' style={{ cursor: "pointer" }} onClick={(e) => {
                      e.stopPropagation();
                      setCommentPrivate(!commentPrivate);
                  }} >
                      <Input type="checkbox" checked={commentPrivate} onChange={(e) => {
                          e.stopPropagation();
                          setCommentPrivate(!commentPrivate);
                      }} /> Private
                  </div>
              }
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color='primary' onClick={submitComment}>Save</Button>
                    <Button onClick={() => setShowCommentModal(false)}>Close</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
      <Footer />
    </div>
  );
};

export default ContractEdit;