import React, { useEffect, useState } from "react";
import { Header, SubNavigationDropdown } from "./";
import { Button, DropdownItem } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../helpers";

export default function ManufacturerAdminHeader(props) {
  const [loading, setLoading] = useState(false);
  const [buildFeature, setBuildFeature] = useState(false);
  const [buildFeaturePreview, setBuildFeaturePreview] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const mfgId = id ? id : props.mfgId;
  const isDetailsRoute = window.location.href.match(/\/admin\/manufacturer\/\d+$/);

  const getMfgEnableBuildStatus = () => {
    api.fetch(`manufacturer/MfgHasBuildFeature/${mfgId}`).then((r) => {
      if (!r.data) return;
      setBuildFeature(r.data.enableBuildFeature);
      setBuildFeaturePreview(r.data.enableBuildFeaturePreview);
    });
  };

  useEffect(getMfgEnableBuildStatus, []);

  return (
    <Header toolbar admin mfgName={props.manufacturerName} loading={loading} mfgAdmin>
      <div className="d-flex justify-content-center me-0">
        {isDetailsRoute ? null : (
          <Button
            active={props.details}
            className="cf-sidebar"
            onClick={() => history.push(`/admin/manufacturer/${mfgId}`)}>
            Details
          </Button>
        )}
        <SubNavigationDropdown buttonText="Relationships">
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/companies`)}>
            Companies
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/stores`)}>
            Stores
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/users`)}>
            Users
          </DropdownItem>
        </SubNavigationDropdown>
        <SubNavigationDropdown buttonText="Builds">
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/add-ons`)}>
            Add Ons
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/bundles`)}>
            Bundles
          </DropdownItem>
          {buildFeature || buildFeaturePreview ? (
            <>
              <DropdownItem
                onClick={() => history.push(`/admin/manufacturer/${mfgId}/build-steps`)}>
                Build Steps
              </DropdownItem>
              <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/builders`)}>
                Builders
              </DropdownItem>
              <DropdownItem
                onClick={() => history.push(`/admin/manufacturer/${mfgId}/inspections`)}>
                Inspections
              </DropdownItem>
            </>
          ) : null}
        </SubNavigationDropdown>
        <SubNavigationDropdown buttonText="Product Options">
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/products`)}>
            Products
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/unit-types`)}>
            Materials
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/manufacturer/${mfgId}/colors`)}>
            Colors
          </DropdownItem>
        </SubNavigationDropdown>
      </div>
    </Header>
  );
}
